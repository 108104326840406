.USPItem {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--base);
}

.USPItem_icon {
	width: 6.4rem;
	height: 6.4rem;
	min-width: 6.4rem;
	min-height: 6.4rem;
}
