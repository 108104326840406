@import 'styles/50-themes/theme-erhvervssikring/2-tools/_tools.typography-mixins.scss';

.Testimonial {
	&___padding {
		padding: var(--spacing--2xl);
		padding-bottom: var(--spacing--3xl);
	}

	display: flex;
	flex-direction: column;
	gap: var(--spacing--md); // 25px. Design is 24px.

	&___background {
		background-color: var(--color-business-light-green);
	}

	&___styleDark {
		color: var(--color-white);
	}

	word-wrap: break-word;
}

.Testimonial_personalInformation {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: var(--spacing--md); // 25px. Design is 24px.

	.Testimonial___large & {
		@include mq('lg') {
			gap: var(--spacing--md); // 25px. Design is 26px.
		}
	}
}

.Testimonial_profilePicture {
	width: 8rem;
	height: 8rem;
	min-width: 8rem;
	min-height: 8rem;
	border-radius: 50%;
	overflow: hidden;

	.Testimonial___large & {
		@include mq('lg') {
			width: 11rem;
			height: 11rem;
			min-width: 11rem;
			min-height: 11rem;
		}
	}
}

.Testimonial_nameAndOccupation {
	display: flex;
	flex-direction: column;

	@include esk-paragraph--sm;
	text-transform: uppercase;

	.Testimonial___large & {
		@include mq('lg') {
			@include esk-paragraph;
		}
	}
}

.Testimonial_occupation {
	opacity: 0.6;
}

.Testimonial_quote {
	@include esk-quote--sm; // Design size & height is 24px & 30px
	color: var(--color-functional-dark-blue);

	.Testimonial___styleDark & {
		color: var(--color-white);
	}

	.Testimonial___large & {
		@include mq('lg') {
			@include esk-quote--lg; // Design size & height is 50px & 58px
		}
	}
}
