.CheckmarkListItem {
	display: flex;
}

.CheckmarkListItem_icon {
	width: 1.8rem;
	height: 1.2rem;
	margin-top: 0.6rem;
}

.CheckmarkListItem_content {
	margin-left: var(--spacing--lg);
	flex: 1 1 0%;
}

.CheckmarkListItem_itemHeading {
	font-size: var(--font-size--md);
	font-weight: var(--font-weight--bold);
	line-height: var(--line-height--3xl);
	margin-bottom: 0.8rem;
}

.CheckmarkListItem_text {
	margin-bottom: var(--spacing--lg);
}

:global(.Erhvervssikring-theme) {
	.CheckmarkListItem_text {
		p {
			font-size: var(--font-size--sm);
		}
	}
}
