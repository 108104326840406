@import 'styles/2-tools/tools.media-query';

.MasterCardTravel {
	width: 100%;
}

.MasterCardTravel_countField {
	width: 50%;

	@include mq('md', max) {
		width: 100%;
	}
}

.MasterCardTravel_label {
	margin-left: var(--spacing--base);
	margin-top: 26px;
	font-weight: var(--font-weight--medium);
}

.MasterCardTravel_checkbox {
	width: 100%;
	margin-bottom: var(--spacing--sm);
}

.MasterCardTravel_dateField {
	width: calc(50% - 4rem);

	@include mq('md', max) {
		width: 100%;
	}
}

.MasterCardTravel_priceField {
	width: 50%;

	@include mq('md', max) {
		width: 100%;
	}
}

.MasterCardTravel_cta {
	display: flex;
	justify-content: center;
	@include mq('md') {
		margin-top: var(--spacing--8xl);
	}
	@include mq('md', max) {
		margin-top: var(--spacing--5-5xl);
	}
}

.MasterCardTravel_subtitle {
	padding: var(--spacing--base);
}

.MasterCardTravel_button {
	margin: 0 var(--spacing--xs);
}

.MasterCardTravel_errorLabel {
	font-weight: var(--font-weight--medium);
	font-size: var(--font-size--lg);

	@include mq('md') {
		margin-bottom: var(--spacing--base);
	}
	@include mq('md', max) {
		margin-bottom: var(--spacing--sm);
	}
}
.MasterCardTravel_errorCard {
	margin-top: 0;
	padding: 0;
}

:global(.Dark-theme) {
	@include mq('lg', max) {
		.MasterCardTravel {
			margin-bottom: 0;
		}
	}
}

:global(.Sydbank-theme) {
	.MasterCardTravel_grid > div {
		padding: calc(var(--spacing--base) / 2) 0;
	}

	.MasterCardTravel_descriptionList div:not(:last-child) {
		background-position: 0 calc(100% - 2.5rem);

		@include mq('md', max) {
			background: none;
			gap: var(--spacing--2xl);
		}

		dt {
			@include mq('md', max) {
				font-size: var(--font-size--xs);
			}

			@include mq('md') {
				font-size: var(--font-size--sm);
			}
		}

		dd {
			line-height: var(--line-height--2xl);

			@include mq('md', max) {
				font-size: var(--font-size--xs);
			}

			@include mq('md') {
				font-size: var(--font-size--sm);
			}

			> span {
				@include mq('md', max) {
					font-size: var(--font-size--xs);
				}

				@include mq('md') {
					font-size: var(--font-size--sm);
				}
			}
		}
	}

	.MasterCardTravel_attentionCard {
		margin: 0;
		padding: 0;
		p {
			font-size: 12px;
		}
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.MasterCardTravel_label {
		margin-left: 0;
	}

	.MasterCardTravel_dateField {
		input[type='date']::-webkit-datetime-edit-text,
		input[type='date']::-webkit-datetime-edit-month-field,
		input[type='date']::-webkit-datetime-edit-day-field,
		input[type='date']::-webkit-datetime-edit-year-field {
			opacity: 0.5;
		}

		&.hasValue {
			input[type='date']::-webkit-datetime-edit-text,
			input[type='date']::-webkit-datetime-edit-month-field,
			input[type='date']::-webkit-datetime-edit-day-field,
			input[type='date']::-webkit-datetime-edit-year-field {
				opacity: 1;
			}
		}
	}

	.MasterCardTravel_descriptionList div:not(:last-child) {
		background-position: 0 calc(100% - 2.5rem);

		@include mq('md', max) {
			background: none;
			gap: var(--spacing--2xl);
		}

		dt {
			@include mq('md', max) {
				font-size: var(--font-size--xs);
			}

			@include mq('md') {
				font-size: var(--font-size--sm);
			}
		}

		dd {
			line-height: var(--line-height--2xl);

			@include mq('md', max) {
				font-size: var(--font-size--xs);
			}

			@include mq('md') {
				font-size: var(--font-size--sm);
			}

			> span {
				@include mq('md', max) {
					font-size: var(--font-size--xs);
				}

				@include mq('md') {
					font-size: var(--font-size--sm);
				}
			}
		}
	}

	.MasterCardTravel_attentionCard {
		margin: 0;
		padding: 0;
		p {
			font-size: 12px;
		}
	}
}
