@import 'src/styles/2-tools/tools.media-query.scss';

@mixin heading--2xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	line-height: var(--line-height--sm);

	@include mq('md', max) {
		font-size: var(--font-size--4xl);
	}

	@include mq('md') {
		font-size: var(--font-size--7xl);
	}
}

@mixin heading--xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	line-height: var(--line-height--sm);

	@include mq('md', max) {
		font-size: var(--font-size--3xl);
	}

	@include mq('md') {
		font-size: var(--font-size--6xl);
	}
}

@mixin heading--lg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	line-height: var(--line-height--lg);

	@include mq('md', max) {
		font-size: var(--font-size--4xl);
	}

	@include mq('md') {
		font-size: var(--font-size--5xl);
	}
}

@mixin heading--md {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--medium);
	line-height: var(--line-height--md);

	@include mq('md', max) {
		font-size: var(--font-size--xl);
	}

	@include mq('md') {
		font-size: var(--font-size--3xl);
	}
}

@mixin heading--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--medium);
	line-height: var(--line-height--2xs);

	@include mq('md', max) {
		font-size: var(--font-size--2xl);
	}

	@include mq('md') {
		font-size: var(--font-size--3xl);
	}
}

@mixin heading--xs {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--medium);
	font-size: var(--font-size--md);
	line-height: var(--line-height--2xl);
}

@mixin manchet {
	// font-family: var(--font-primary);
	// font-weight: var(--font-weight--bold);
	// font-size: var(--font-size--xl);
	// line-height: var(--line-height--md);
}

@mixin paragraph--xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xl);
	line-height: var(--line-height--xl);
}

@mixin paragraph--md {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--light);
	line-height: var(--line-height--2xl);

	@include mq('md', max) {
		font-size: var(--font-size--md);
	}

	@include mq('md') {
		font-size: var(--font-size--lg);
	}
}

@mixin paragraph {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--light);
	font-size: var(--font-size--md);
	line-height: var(--line-height--2xl);
}

@mixin paragraph--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--2xl);
}

@mixin paragraph--xs {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xs);
	line-height: var(--line-height--2xl);
}

@mixin text-link--md {
	// font-family: var(--font-secondary);
	// font-weight: var(--font-weight--bold);
	// font-size: var(--font-size--md);
	// line-height: var(--line-height--xl);
}

@mixin text-link--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--extrablack);
	line-height: var(--line-height--md);
}

@mixin formfield-label {
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--medium);
	color: currentColor;
	cursor: pointer;

	@include mq('md', max) {
		font-size: var(--font-size--xs);
	}

	@include mq('md') {
		font-size: var(--font-size--sm);
	}
}
