@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/2-tools/tools.aspect-ratio.scss';
.FullGridWidthImage {
	margin: calc(var(--spacing--5-5xl) + var(--spacing--base)) auto;

	&___negativeTopMargin {
		margin-top: calc(var(--spacing--5-5xl) * -1);
	}
}

.FullGridWidthImage_asset {
	@include mq('sm', max) {
		@include aspect-ratio(1, 1);
	}
}
