@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/2-tools/tools.display-mixins.scss';
@import 'styles/2-tools/tools.typography-mixins';

.Navigation {
	@include mq('lg') {
		margin: 0 var(--spacing--lg);
		display: flex;
	}

	&___external {
		background-color: var(--color-primary-light);
	}
}

.Navigation_heading {
	@include hidden-absolute;
}

.Navigation_list {
	list-style: none;
	flex-direction: column;
	text-align: center;
	display: flex;
	align-items: center;

	@include mq('lg', max) {
		max-width: var(--content-size--xs);
		margin-left: auto;
		margin-right: auto;
	}

	@include mq('lg') {
		flex-direction: row;
		flex-wrap: wrap;
	}

	&___external {
		width: auto;
		max-width: unset;
		text-align: right;
		justify-content: end;
		flex-direction: row-reverse;
		padding: var(--font-size--xs) var(--spacing--md) var(--font-size--xs) 0;

		@include mq('lg') {
			padding-right: var(--spacing--3xl);
		}
	}
	&_rightSide {
		justify-content: right;
		flex-grow: 1;
		@include mq('lg', max) {
			justify-content: center;
			flex-grow: 0;
			padding-top: 24px;
		}
	}

	&_image {
		.Navigation_item {
			margin: var(--spacing--xs) 0;
			&:after {
				display: none;
			}
		}
		@include mq('lg', max) {
			display: none;
		}
	}
}

.Navigation_item {
	text-align: left;
	margin-right: var(--spacing--xl);

	.Navigation_list___external & {
		display: flex;
		align-items: center;
		margin-right: 0;
		margin-left: var(--spacing--xl);
	}

	&:hover a {
		color: var(--color-white);
	}

	@include mq('lg') {
		text-align: center;

		&___myProfile {
			display: none;
		}

		&:hover a {
			color: var(--color-primary);
		}
	}

	@include mq('lg', max) {
		position: relative;
		margin-bottom: var(--spacing--2xs);
		padding-right: var(--grid-gutter);
		padding-left: var(--spacing--base);

		.Navigation_list___external & {
			padding: 0;
			margin-bottom: 0;
		}
	}
}

.Navigation_link {
	text-decoration: none;
	white-space: nowrap;
	font-size: var(--font-size--2xl);
	display: inline-block;
	margin: var(--spacing--xs) 0;
	border-bottom: var(--border--sm) solid transparent;
	cursor: pointer;
	font-weight: var(--font-weight--light);
	color: var(--color-dark);

	&___active {
		font-weight: var(--font-weight--semibold) !important;
	}

	&___myProfile {
		cursor: default;
	}

	.Navigation_list___external & {
		display: flex;
		align-items: center;
		height: 100%;
		margin: 0;
		text-decoration: none;
		font-size: var(--font-size--md);

		&___current {
			font-weight: var(--font-weight--bold);
		}
	}

	@include mq('lg', max) {
		margin: var(--spacing--2xs) 0;
	}

	@include mq('lg') {
		font-weight: var(--font-weight--light);
		font-size: var(--font-size--md);

		&___myProfile {
			display: none;
		}
	}

	@include mq('xs', max) {
		.Navigation_list___external & {
			font-size: var(--font-size--sm);
		}
	}
}

:global(.Dark-theme--masterCardPage) {
	.Navigation_link {
		color: var(--color-white);
	}
}
:global(.Codan-theme) {
	.Navigation_link___active {
		font-weight: var(--font-weight--bold);
	}
}

:global(.Erhvervssikring-theme) {
	.Navigation {
		@include mq('lg') {
			margin-left: var(--spacing--7xl);
		}
	}

	.Navigation_item {
		@include mq('lg', max) {
			&:before {
				content: none;
			}
		}
	}

	.Navigation_link {
		&___active {
			border: none;

			@include mq('lg', max) {
				&:before {
					content: ' ';
					background-image: url('/public/icons/arrow.png');
					background-repeat: no-repeat;
					background-position: center center;
					display: inline-block;
					position: absolute;
					top: 1.5rem;
					left: 0;
					width: 1rem;
					height: 1.3rem;
				}
			}
		}
	}
}

:global(.Sydbank-theme.Dark-theme) {
	.Navigation_list {
		.Navigation_item {
			color: var(--color-white);
			.Navigation_link {
				color: var(--color-white);
			}
		}
	}
}
:global(.Sydbank-theme) {
	.Navigation_list {
		// justify-content: center;
		list-style: none;
		gap: 24px;
		text-transform: uppercase;
		& .Navigation_link {
			font-family: Ab-WorkSans, sans-serif;
			font-weight: var(--font-weight--medium);
			font-size: var(--font-size--md);
			&___active {
				font-weight: var(--font-weight--semibold) !important;
			}
		}
		.Navigation_item {
			position: relative;
			text-transform: uppercase;
			color: var(--color-ab-primary);
			text-decoration: none;
			letter-spacing: 0.06em;
			-webkit-font-smoothing: antialiased;
			&:after {
				content: '';
				display: block;
				height: 2px;
				width: 32px;
				background-color: var(--color-sky-blue);
				position: absolute;
				bottom: 4px;
				left: calc(50% - 16px);
				transform: scaleX(0);
				transition: transform 0.15s cubic-bezier(0.55, 0.085, 0.68, 0.53);
				@include mq('lg', max) {
					display: none;
				}
			}
			&:hover {
				&:after {
					transform: scaleX(100%);
				}
			}
			&.image {
				&:after {
					display: none;
				}
			}
		}
		&_image {
			.Navigation_item {
				min-width: 11rem;
				&:after {
					display: none;
				}
			}
		}
	}
}
:global(.Dark-theme) {
	.Navigation_list {
		margin-left: 20px;
		.Navigation_item {
			color: var(--color-white);
			.Navigation_link {
				color: var(--color-white);
			}
		}
	}
}
