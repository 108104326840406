.Codan-theme {
	--color-primary: #050faa;
	--color-primary-light: #f5f5f3;
	--color-primary-contrast: #1e27b2;
	--color-primary-dark: #0f195a;
	--color-tertiary: #ffd755;
	--color-dark-blue: #0f185a;
	--color-light-blue: #e6e7f6;

	// Utilities colors
	--color-active: #0f185a;
	--color-inactive: #afafb1;

	--color-primary-light-contrast: #b4b7e6;

	// Hover colors
	--color-hover-tertiary: #f6d053;
}
