@import 'styles/2-tools/tools.media-query.scss';

.CheckmarkList {
	@include mq('md') {
		flex: 0 0 calc(50% - var(--spacing--xs));
		max-width: calc(50% - var(--spacing--xs));
		width: calc(50% - var(--spacing--xs));
	}
}

.CheckmarkList_listContent {
	padding: var(--spacing--6xl) var(--spacing--3xl) var(--spacing--7xl);
}

.CheckmarkList_listHeading {
	margin-bottom: var(--spacing--3xl);
}

.CheckmarkList_list {
	list-style: none;
}

:global(.Erhvervssikring-theme) {
	.CheckmarkList {
		&___Dark {
			background-color: var(--color-functional-dark-blue);
			color: var(--color-white);
		}

		&___Light {
			background-color: var(--color-functional-light-blue);
		}
	}
}
