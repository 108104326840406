//https://fonts.google.com/specimen/Open+Sans
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Not used as only Codan uses custom fonts */
$rootpath: './5-fonts/ABG';

@font-face {
	font-family: 'AlmBrand';
	font-style: normal;
	font-weight: 400;
	src: url($rootpath + '/almbrandamalia-regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Ab-AmaliaPro';
	font-display: swap;
	src: url($rootpath + '/Amalia/AlmBrandAmalia-Black.otf') format('opentype'),
		url($rootpath + '/Amalia/AlmBrandAmalia-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Ab-AmaliaPro';
	font-display: swap;
	src: url($rootpath + '/Amalia/AlmBrandAmalia-Bold.otf') format('opentype'),
		url($rootpath + '/Amalia/AlmBrandAmalia-Bold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Ab-WorkSans';
	src: url($rootpath + '/WorkSans/WorkSans-Bold.woff2') format('woff2'),
		url($rootpath + '/WorkSans/WorkSans-Bold.woff') format('woff'),
		url($rootpath + '/WorkSans/WorkSans-Bold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Ab-WorkSans';
	src: url($rootpath + '/WorkSans/WorkSans-Medium.woff2') format('woff2'),
		url($rootpath + '/WorkSans/WorkSans-Medium.woff') format('woff'),
		url($rootpath + '/WorkSans/WorkSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Ab-WorkSans';
	src: url($rootpath + '/WorkSans/WorkSans-Regular.woff2') format('woff2'),
		url($rootpath + '/WorkSans/WorkSans-Regular.woff') format('woff'),
		url($rootpath + '/WorkSans/WorkSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Ab-WorkSans';
	src: url($rootpath + '/WorkSans/WorkSans-Light.woff2') format('woff2'),
		url($rootpath + '/WorkSans/WorkSans-Light.woff') format('woff'),
		url($rootpath + '/WorkSans/WorkSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

// @font-face {
// 	font-family: 'Codan';
// 	font-style: normal;
// 	font-weight: 100;
// 	src: local(''), url($rootpath + '/Codan-Thin.woff2') format('woff2'),
// 		url($rootpath + '/Codan-Thin.woff') format('woff');
// }
// @font-face {
// 	font-family: 'Codan';
// 	font-style: normal;
// 	font-weight: 200;
// 	src: local(''), url($rootpath + '/Codan-Regular.woff2') format('woff2'),
// 		url($rootpath + '/Codan-Regular.woff') format('woff');
// }
// @font-face {
// 	font-family: 'Codan';
// 	font-style: normal;
// 	font-weight: 500;
// 	src: local(''), url($rootpath + '/Codan-Medium.woff2') format('woff2'),
// 		url($rootpath + '/Codan-Medium.woff') format('woff');
// }
// @font-face {
// 	font-family: 'Codan';
// 	font-style: normal;
// 	font-weight: 700;
// 	src: local(''), url($rootpath + '/Codan-Bold.woff2') format('woff2'),
// 		url($rootpath + '/Codan-Bold.woff') format('woff');
// }
// @font-face {
// 	font-family: 'Codan';
// 	font-style: normal;
// 	font-weight: 900;
// 	src: local(''), url($rootpath + '/Codan-Heavy.woff2') format('woff2'),
// 		url($rootpath + '/Codan-Heavy.woff') format('woff');
// }
