@import 'styles/2-tools/tools.media-query';

.MasterCardContent_heading {
	font-weight: var(--font-weight--medium);
}

.MasterCardContent_text {
	white-space: pre-line;
}

.MasterCardContent_additionalText {
	margin-top: var(--spacing--xs);
	margin-bottom: var(--spacing--xs);
}
