@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/2-tools/tools.typography-mixins.scss';

.AttentionCard {
	max-width: calc(var(--content-size--default) + var(--spacing--md) * 2);
	width: 100%;
	padding: 0;
	margin-left: auto;
	margin-right: auto;
	&___pending {
		padding: 0;
	}
	&___small {
		margin-top: var(--spacing--3xl);
		margin-bottom: var(--spacing--lg);
	}
	&___medium {
		@include mq('md', max) {
			margin: var(--spacing--5xl) auto;
		}
		@include mq('md') {
			margin: var(--spacing--6xl) auto;
		}
	}
	&___large {
		margin-top: var(--spacing--lg);
		margin-bottom: var(--spacing--8xl);
	}
	&___disclaimer {
		@include mq('md', max) {
			margin: var(--spacing--lg) auto;
		}
		@include mq('md') {
			margin: var(--spacing--2xl) auto;
		}
	}
}

.AttentionCard_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	.AttentionCard___error & {
		background-color: var(--color-error-contrast);
	}
	.AttentionCard___pending & {
		background-color: var(--color-pending-contrast);
	}
	.AttentionCard___info & {
		background-color: var(--color-primary-light);
	}
	.AttentionCard___success & {
		background-color: var(--color-success-contrast);
		padding: var(--spacing--3xl);
	}
	.AttentionCard___small & {
		min-height: 9rem;
		padding: var(--spacing--md);

		@include paragraph--sm;
	}
	.AttentionCard___medium & {
		min-height: 19rem;
		padding: var(--spacing--lg);
	}
	.AttentionCard___large & {
		min-height: 35rem;
		padding: var(--spacing--2xl);

		:global(.Sydbank-theme) & {
			.AttentionCard_text > p {
				font-size: var(--font-size--xl);
			}
		}
	}
	.AttentionCard___disclaimer & {
		min-height: auto;
		flex-direction: row;
		align-items: flex-start;
		justify-content: left;
		text-align: left;
		padding: 0;
	}

	.AttentionCard___disclaimer.AttentionCard___small & {
		align-items: center;
	}

	.AttentionCard___info &,
	.AttentionCard___error & {
		padding: var(--spacing--base);
	}
}

.AttentionCard_container {
	.AttentionCard___disclaimer & {
		display: flex;
	}
}

.AttentionCard_icon {
	margin-bottom: var(--spacing--sm);
	max-width: 6rem;
	margin-left: auto;
	margin-right: auto;

	.AttentionCard___medium & {
		max-width: var(--icon-size--xl);
	}

	.AttentionCard___disclaimer & {
		margin-right: var(--spacing--xs);
		margin-left: 0;
		height: 2.6rem;
		width: auto;
		min-width: 2.6rem;
		margin-bottom: var(--spacing--2xs);
	}
}
.AttentionCard_iconImage {
	height: 100%;
}

.AttentionCard_text p {
	.AttentionCard___pending & {
		margin-bottom: 0;
	}
	.AttentionCard___medium & {
		@include mq('md', max) {
			font-size: var(--font-size--md);
		}
		@include mq('md') {
			font-size: var(--font-size--lg);
		}
	}

	.AttentionCard___info & {
		font-size: var(--font-size--lg);
	}
	margin-bottom: var(--spacing--2xs);
}

:global(.Dark-theme) {
	.AttentionCard_text {
		color: var(--color-white);
	}
	.AttentionCard___disclaimer {
		background-color: var(--color-background-lighter);
	}

	.AttentionCard___info .AttentionCard_wrapper {
		background-color: var(--color-background);
	}
	.AttentionCard___success .AttentionCard_wrapper {
		background-color: var(--color-background-lighter);
		padding: 0 var(--spacing--4xl);
	}
	.AttentionCard___error .AttentionCard_wrapper {
		background-color: var(--color-background-lighter);
		padding: 0 var(--spacing--4xl);
	}

	.AttentionCard___error .AttentionCard_container {
		width: 100%;
		height: 100%;
		background-color: var(--color-error-contrast);
		padding: var(--spacing--xl) var(--spacing--base);
	}
	.AttentionCard___success .AttentionCard_container {
		width: 100%;
		height: 100%;
		background-color: var(--color-background);
		padding: var(--spacing--xl) var(--spacing--base);
	}
}
