@import 'src/styles/2-tools/tools.action.scss';
@import 'src/styles/50-themes/theme-erhvervssikring/2-tools/tools.action.scss';

.u-rte-link-as-button {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: var(--spacing--5xl);

	@include mq('md') {
		margin-top: var(--spacing--3xl);
		.Erhvervssikring-theme & {
			margin-bottom: var(--spacing--3xl);
		}
	}

	@include mq('md', max) {
		margin-top: var(--spacing--2xl);
		.Erhvervssikring-theme & {
			margin-bottom: var(--spacing--2xl);
		}
	}

	a {
		@include button;
		color: var(--color-black);
		@include border-default;
		text-transform: uppercase;
		font-size: var(--font-size--sm);
		font-weight: var(--font-weight--medium);

		&:hover,
		&:focus {
			background-color: var(--color-background-contrast-dark);
			color: var(--color-black);
		}

		.u-content-section--background-light-gray & {
			color: var(--color-black);
			background: var(--color-primary);
			border-color: var(--color-primary);

			&:hover,
			&:focus {
				color: var(--color-dark);
				background-color: var(--color-secondary);
				border-color: var(--color-secondary);
			}
		}
		.Erhvervssikring-theme & {
			// Primary button style
			color: var(--color-black);
			background: var(--color-primary);
			text-transform: uppercase;
			font-size: var(--font-size--sm);
			font-weight: var(--font-weight--medium);
			border: none;
			&:hover,
			&:focus {
				color: var(--color-black);
				background-color: var(--color-hover-primary);
			}
		}

		.Codan-theme & {
			// Primary button style
			color: var(--color-dark-blue);
			background-color: var(--color-tertiary);
			font-weight: var(--font-weight--medium);
			border: none;

			&:hover,
			&:focus {
				background-color: var(--color-hover-tertiary);
			}
		}
	}
}

.u-rte-link-as-primary-button {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: var(--spacing--5xl);
	@include mq('md') {
		margin-top: var(--spacing--3xl);
		.Erhvervssikring-theme & {
			margin-bottom: var(--spacing--3xl);
		}
	}

	@include mq('md', max) {
		margin-top: var(--spacing--2xl);
		.Erhvervssikring-theme & {
			margin-bottom: var(--spacing--2xl);
		}
	}

	a {
		@include button;
		color: var(--color-black);
		background: var(--color-primary);
		text-transform: uppercase;
		font-size: var(--font-size--sm);
		font-weight: var(--font-weight--medium);
		&:hover,
		&:focus {
			color: var(--color-dark);
			background-color: var(--color-secondary);
		}

		.Codan-theme & {
			// Primary button style
			color: var(--color-dark-blue);
			background-color: var(--color-tertiary);
			font-weight: var(--font-weight--medium);
			border: none;

			&:hover {
				background-color: var(--color-hover-tertiary);
			}
		}

		.Erhvervssikring-theme & {
			&:hover,
			&:focus {
				background-color: var(--color-hover-primary);
			}
		}
	}
}

.u-rte-checkmark-list {
	list-style: none;

	li {
		position: relative;
	}

	li::before {
		content: '';
		position: absolute;
		left: 0;
		height: 1em;
		width: 0.5em;
		border-bottom: var(--border--md) solid var(--color-primary);
		border-right: var(--border--md) solid var(--color-primary);
		transform: translateX(calc(var(--spacing--xs) * -1)) rotate(45deg);
	}
}

.u-rte--heading {
	&-xs {
		@include heading--xs;
	}

	&-sm {
		@include heading--sm;
	}

	&-md {
		@include heading--md;
	}

	&-lg {
		@include heading--lg;
	}

	&-xl {
		@include heading--xl;
	}

	&-2xl {
		@include heading--2xl;
	}
}

.u-rte--enlarge {
	@include mq('md', max) {
		font-size: var(--font-size--5xl);
	}
	@include mq('md') {
		font-size: var(--font-size--6xl);
	}
}
.u-rte--enlarge-minor {
	font-size: var(--font-size--5xl);
}
