@import 'styles/2-tools/tools.media-query.scss';
.Footer {
	margin-top: var(--spacing--6xl);
	width: 100%;

	@include mq('md') {
		margin-top: var(--spacing--9xl);
	}
}

.Footer_cell {
	@include mq('md') {
		&:first-child {
			padding-right: var(--spacing--md);
		}

		&:last-child {
			padding-left: var(--spacing--md);
		}
	}
}

.Footer_primary {
	background-color: var(--color-primary);
	padding-top: var(--spacing--3xl);
	padding-bottom: var(--spacing--6xl);

	@include mq('md') {
		padding-top: var(--spacing--5xl);
		padding-bottom: var(--spacing--7xl);
	}
}

.Footer_secondary {
	background-color: var(--color-primary-light);
	padding-top: var(--spacing--4xl);
	padding-bottom: var(--spacing--5-5xl);
}

:global(.Erhvervssikring-theme) {
	.Footer {
		margin-top: 0;

		@include mq('md') {
			margin-top: 0;
		}
	}
}

:global(.Dark-theme) {
	.Footer {
		margin-top: 0;
		color: var(--color-white);
	}

	.Footer_primary {
		background-color: var(--color-background);
	}
}

:global(.Codan-theme) {
	.Footer_primary {
		color: var(--color-white);
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.Footer_primary {
		background-color: var(--color-dove-gray);
	}
}

:global(.Sydbank-theme) {
	.Footer_primary {
		color: var(--color-white);
		padding-bottom: var(--spacing--3xl);

		@include mq('md') {
			padding-bottom: var(--spacing--5xl);
		}
	}
	.Footer_secondary {
		background-color: var(--color-black);
		padding-top: var(--spacing--xs);
		padding-bottom: var(--spacing--xs);
		.FooterCard_links {
			flex-direction: row !important;
		}
	}
	.Footer_column_heading {
		text-transform: uppercase;
		color: var(--color-white);
		text-decoration: none;
		letter-spacing: 0.05em;
		-webkit-font-smoothing: antialiased;
		font-weight: 500;
		font-size: 18px;
	}
}
