.Picture {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;

	&.isFallback {
		background-position: center center;
		background-size: cover;
		top: 0;
		left: 0;
	}
}

.Picture_asset {
	display: block;

	.Picture___cover & {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
