.LogoutButton {
	width: 100%;
	display: flex;
	align-items: center;
	border: none;
	background: none;
}

.LogoutButton_icon {
	display: inline-block;
	width: 3.5rem;
	margin-right: var(--spacing--xs);
	padding: var(--spacing--2xs);
	background-color: var(--color-white);
	border-radius: var(--corner-circle);
	.LogoutButton___large & {
		width: 4.5rem;
	}
}

.LogoutButton_iframe {
	display: none;
}
