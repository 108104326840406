.Erhvervssikring-theme {
	// Color palette
	--color-puerto-rico: #47c4a9;
	--color-puerto-rico2: #4cd2b5;
	--color-iceberg: #d7f2e9;
	--color-rhino: #323c64;
	--color-mischka: #d1d5de;
	--color-athens-gray-dark: #e8eaee; // 50% mischka on white
	--color-athens-gray-light: #f3f4f6; // 25% mischka on white
	--color-gray-nurse: #dce0da;
	--color-gray-nurse-light: #eef0ed; // 50% gray-nurse on white
	--color-gray-nurse-lighter: #f6f7f6; // 25% gray-nurse on white
	--color-snow-drift: #eef3ec;
	--color-zircon: #f6f9ff;
	--color-mine-shaft: #2e2b2b;
	--color-white: #ffffff;

	// ESK colors (See https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3598)
	// ESK Primary colors
	--color-business-green: var(--color-puerto-rico);
	--color-business-light-green: var(--color-iceberg);
	--color-functional-dark-blue: var(--color-rhino);
	// ESK Secondary colors
	--color-functional-gray-blue: var(--color-mischka);
	--color-functional-gray-blue-50: var(--color-athens-gray-dark);
	--color-functional-gray-blue-25: var(--color-athens-gray-light);
	--color-functional-light-blue: var(--color-zircon);
	--color-functional-black: var(--color-mine-shaft);
	--color-business-medium-gray: var(--color-gray-nurse);
	--color-business-medium-gray-50: var(--color-gray-nurse-light);
	--color-business-medium-gray-25: var(--color-gray-nurse-lighter);
	--color-business-light-gray: var(--color-snow-drift);

	// Theme colors
	--color-primary: var(--color-business-green);
	--color-hover-primary: var(--color-puerto-rico2);
	// --color-primary-dark: var(--color-dark-orange);
	// --color-primary-contrast: var(--color-texas-rose);
	--color-primary-light: var(--color-business-light-green);
	// --color-primary-light-contrast: var(--color-flesh);
	--color-secondary: var(--color-iceberg);
	// --color-secondary-light: var(--color-salomie);
	--color-tertiary: var(--color-rhino);

	// Utilities colors
	--color-dark: var(--color-mine-shaft);
	--color-light: var(--color-white);

	// Utilities colors generic
	// --color-error: var(--color-red-ribbon);
	// --color-error-contrast: var(--color-wisp-ping);
	// --color-success: var(--color-mountain-meadow);
	// --color-success-contrast: var(--color-narvik);
	// --color-pending: var(--color-broom);
	// --color-pending-contrast: var(--color-witch-haze); // to do change
	--color-dark: var(--color-mine-shaft);
	--color-light: var(--color-white);
	// --color-active: var(--color-primary);
	// --color-inactive: var(--color-gradient--20);

	// Background colors generic
	// --color-background-contrast: var(--color-athens-gray);
	// --color-background-contrast-light: var(--color-athens-gray-light);
	// --color-background-contrast-dark: var(--color-athens-gray-dark);

	// Border colors generic
	// --color-border--dark: var(--color-dark);
	// --color-border--gray: var(--color-gray-suit);
	// --color-border--light: var(--color-alto);
}
