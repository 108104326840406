@mixin border-default($color: var(--color-black)) {
	border: 0.1rem solid $color;
}

@mixin border-thin($color: var(--color-gradient--5)) {
	border: 0.3rem solid $color;
}

@mixin border-primary($color: var(--color-primary)) {
	border: 0.2rem solid $color;
}
