@import 'styles/2-tools/tools.media-query.scss';

.InfoboxModule {
	margin-top: var(--spacing--8xl);
	margin-bottom: var(--spacing--8xl);
}

.InfoboxModule_item {
	&:not(:first-child) {
		margin-top: var(--spacing--2-5xl);

		@include mq('md') {
			margin-top: 0;
		}
	}
}
