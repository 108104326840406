/* ------------------------------------ *\
		elements.page
\* ------------------------------------ */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/

@import 'src/styles/2-tools/tools.typography-mixins';

html {
	font-family: var(--font-primary);
	font-size: 0.625rem; //Equivalent to 10px at default browser font-size of 16px. This is more acessible than 10px. For more, see https://www.aleksandrhovhannisyan.com/blog/use-rems-for-font-size/#dont-use-pixels-for-font-size
}

body {
	@include paragraph;
}
