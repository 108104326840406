@import 'styles/2-tools/tools.media-query.scss';

.CheckmarkListModule {
	margin-top: var(--spacing--6xl);
	margin-bottom: var(--spacing--8xl);
}

.CheckmarkListModule_grid {
	@include mq('md') {
		display: flex;
		gap: calc(var(--spacing--xs) * 2);
	}
}
