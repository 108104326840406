/* ------------------------------------ *\
		elements.anchor
\* ------------------------------------ */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/
.Dark-theme.Sydbank-theme {
	a {
		color: var(--color-sky-blue);
	}
}
