@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/2-tools/tools.display-mixins.scss';
@import 'styles/2-tools/tools.typography-mixins.scss';

$text-spacing: var(--spacing--lg);
$circle-size-lg: 4rem;
$circle-size-sm: 2.3rem;

.StepIndicator {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: var(--color-primary-light);
	min-height: var(--content-size--2xs);
	align-items: center;
	flex-flow: column;
	padding: var(--spacing--md) var(--spacing--lg);
}

.StepIndicator_heading {
	text-align: center;
	margin-bottom: var(--spacing--md);
	z-index: 1;
	max-width: var(--content-size--3xs);

	@include mq('md') {
		max-width: 100%;
	}
}

.StepIndicator_list {
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-height: 4rem;
	list-style-type: none;
	max-width: var(--content-size--3xs);
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: var(--border--md);
		background: var(--color-primary);
		top: calc($circle-size-sm / 2);
	}

	@include mq('md') {
		max-width: var(--content-size--xs);

		&:before {
			top: calc($circle-size-lg / 2);
		}
	}
}
.StepIndicator_item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.StepIndicator_itemText {
	position: absolute;
	bottom: calc($text-spacing * -1);
	font-size: var(--font-size--sm);
	min-width: 10rem;
	text-align: center;
	.StepIndicator_item___current & {
		font-weight: var(--font-weight--medium);
	}

	@include mq('md', max) {
		@include visually-hidden;
	}
}

.StepIndicator_itemCircle {
	border-radius: var(--corner-circle);
	border: var(--border--md) solid var(--color-primary);
	text-align: center;
	background-color: var(--color-primary-light);
	padding: var(--spacing--2xs);
	width: $circle-size-lg;
	height: $circle-size-lg;

	@include mq('md', max) {
		width: $circle-size-sm;
		height: $circle-size-sm;
		position: relative;
	}

	.StepIndicator_item___current &,
	.StepIndicator_item___complete & {
		background: var(--color-primary);
	}
}

.StepIndicator_itemCheckMark {
	display: none;
	color: var(--color-white);
	width: 2.5rem;
	height: 2.5rem;

	@include mq('md', max) {
		width: 1.5rem;
		height: 1.5rem;
		position: absolute;
		top: 13%;
		left: 13%;
	}

	.StepIndicator_item___complete & {
		display: inline;
	}
}

@import 'styles/50-themes/theme-dark/2-tools/tools.typography-mixins.scss';
:global(.Dark-theme) {
	.StepIndicator {
		justify-content: flex-start;
		background-color: var(--color-background);
		color: var(--color-white);
		max-width: var(--content-size--default);
		margin-left: auto;
		margin-right: auto;
		padding-top: var(--spacing--2xl);
		min-height: 19rem;
		@include mq('md') {
			padding-top: var(--spacing--3xl);
			border-radius: calc(var(--corner-size--xl) / 2) calc(var(--corner-size--xl) / 2) 0 0;
			min-height: var(--content-size--2xs);
		}
	}
	.StepIndicator_heading {
		color: var(--color-white) !important;
		z-index: 1;
		margin-bottom: var(--spacing--base);
		@include heading--lg;
		@include mq('md') {
			margin-bottom: var(--spacing--xl);
		}
	}
	.StepIndicator_itemCircle {
		background-color: var(--color-background);
	}
	.StepIndicator_item___current .StepIndicator_itemCircle,
	.StepIndicator_item___complete .StepIndicator_itemCircle {
		background: var(--color-primary);
	}
	.StepIndicator_itemCheckMark {
		color: var(--color-background);
	}
}

:global(.Sydbank-theme) {
	.StepIndicator_heading {
		font-family: var(--font-secondary);
		color: var(--color-primary);
		font-size: var(--font-size--4xl);
	}
}
