@import 'styles/2-tools/tools.display-mixins.scss';
@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/2-tools/tools.keyboard-focus.scss';

// Without search
$header-height-open: var(--header-height--xs);

// With search
// $header-height-open: 18rem;

.Header {
	position: sticky;
	top: 0;
	font-size: var(--font-size--md);
	color: var(--color-dark);
	z-index: var(--z-index--header);
	background-color: var(--color-white);
	will-change: transform, background-color, opacity, margin;
	box-shadow: 0 0 0.05rem 0.1rem var(--color-background-contrast-dark);
	display: flex;
	justify-content: space-evenly;
	@include mq('lg', max) {
		font-size: var(--font-size--lg);

		&___isOpen {
			background-color: var(--color-primary);
			height: $header-height-open;
		}
	}
}

.Header_container {
	min-height: var(--header-height--xs);
	width: 100%;
	@include mq('lg') {
		min-height: var(--header-height--lg);
	}
}

.Header_container,
.Header_external ul {
	max-width: var(--content-size--default);
	margin: 0;
	padding: 0 var(--spacing--md);
	@include mq('sm', max) {
		padding: 0 var(--spacing--3xl);
	}
	@include mq('lg') {
		padding: 0;
		min-height: var(--header-height--lg);
	}

	.Header___Privatsikring &,
	:global(.Erhvervssikring-theme),
	:global(.Codan-theme) & {
		max-width: calc(var(--content-size--large) + var(--spacing--md) * 2);
	}
	:global(.Dark-theme--masterCardPage) & {
		max-width: var(--content-size--large);
		@include mq('lg') {
			margin-left: 10%;
		}
	}
}

.Header_navigation {
	text-align: right;
	flex: 1;
	display: flex;

	@include mq('lg', max) {
		order: 2;
		flex: initial;
		margin-left: auto;
		margin-right: var(--spacing--md);
	}
}

.Header_nav {
	display: block;
	transition: opacity 0.3s ease-in 0.3s, top 0.3s ease-in 0.3s;
	position: absolute;
	width: 100%;
	top: 20rem;
	left: 0;
	margin: auto;

	@include mq('lg', max) {
		visibility: hidden;
		opacity: 0;
		padding: var(--spacing--4xl) var(--spacing--md);

		&___isOpen {
			height: 100vh;
			visibility: visible;
			opacity: 1;
			top: $header-height-open;
		}
	}

	@include mq('sm', max) {
		padding: var(--spacing--base) var(--spacing--md);
	}

	@include mq('lg') {
		padding: 0;
		background-color: transparent;
		height: auto;
		display: block;
		position: static;
	}

	@include mq('xl') {
		nav {
			margin: 0 var(--spacing--7xl);
		}
	}
}

.Header_navBackground {
	position: absolute;
	background: var(--color-primary-contrast);
	z-index: -2;
	transition: opacity 0.3s ease-in-out;
	top: $header-height-open;
	right: 0;
	width: 100%;
	opacity: 0;
	visibility: hidden;

	@include mq('lg', max) {
		&___isOpen {
			height: 100vh;
			visibility: visible;
			opacity: 1;
		}
	}
}

.Header_icon {
	height: 4rem;
	width: 2.4rem;
	display: inline-block;
	position: relative;
}

.Header_iconBar {
	top: 0;
	width: 100%;
	height: 0.14rem;
	display: block;
	margin: var(--spacing--xs) auto;
	position: absolute;
	background-color: var(--color-white);
	border-radius: var(--corner-size--sm);

	.Header_navToggle___isOpen & {
		background-color: var(--color-dark);
	}

	&:nth-of-type(1) {
		top: 0.2rem;
		width: 1.6rem;
		left: 0.4rem;
		transition: opacity 0.2s ease 0.2s;

		.Header_navToggle___isOpen & {
			transition: none;
			top: 1rem;
			opacity: 0;
		}
	}

	&:nth-of-type(2) {
		top: 0.9rem;
		transition: all 0.2s ease 0.1s, transform 0.2s ease-out 0.2s;

		.Header_navToggle___isOpen & {
			transform: rotate(45deg);
		}
	}

	&:nth-of-type(3) {
		top: 0.9rem;
		transition: all 0.2s ease 0.1s, transform 0.2s ease-out 0.2s;

		.Header_navToggle___isOpen & {
			left: 0;
			transform: rotate(-45deg);
		}
	}

	&:nth-of-type(4) {
		top: 1.6rem;
		width: 1.6rem;
		left: 0.4rem;
		transition: opacity 0.2s ease 0.2s;

		.Header_navToggle___isOpen & {
			transition: none;
			top: 1rem;
			opacity: 0;
		}
	}
}

.Header_navToggle {
	width: 4rem;
	height: 4rem;
	border-radius: var(--corner-circle);
	border-style: solid;
	border: 0;
	background-color: var(--color-primary);
	position: relative;
	padding: 0;

	&___isOpen {
		background-color: var(--color-white);
	}

	@include mq('lg') {
		display: none;
	}
}

.Header_ctaButton {
	@include mq('lg', max) {
		display: none;
	}
}

.Header_cart {
	margin-right: var(--spacing--xs);

	@include mq('lg', max) {
		order: 1;
	}

	&___isOpen {
		display: none;
	}
}

.Header_navMobileBottomSection {
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: var(--spacing--xl);
	max-width: var(--content-size--xs);
	margin-left: auto;
	margin-right: auto;
	font-size: var(--font-size--md);

	@include mq('lg') {
		display: none;
	}
}

.Header_navMobileBottomSectionItem {
	width: 50%;
	min-width: 17.5rem;
	border: none;
	background: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: var(--spacing--xs);

	@include mq('xs', max) {
		width: auto;
		min-width: auto;
		margin-left: -5px;
		margin-right: var(--spacing--sm);
	}
}

:global(.Dark-theme--masterCardPage) {
	.Header {
		box-shadow: none;
		color: var(--color-white);
		background-color: var(--color-background);
	}

	.Header_navBackground {
		background: var(--color-background-lighter);
	}

	.Header_iconBar {
		background-color: var(--color-dark);
	}
}

:global(.Erhvervssikring-theme) {
	.Header_navBackground {
		background: var(--color-primary);
	}
}

:global(.Codan-theme) {
	.Header_nav {
		@include mq('lg', max) {
			&___isOpen p,
			a {
				color: white;
			}
		}
	}

	.Header_navToggle {
		background-color: var(--color-tertiary);

		&___isOpen {
			background-color: var(--color-white);
		}
	}

	.Header_iconBar {
		background-color: var(--color-primary);
	}

	.Header___mobileLogout {
		color: white;
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.Header {
		box-shadow: none;
		color: var(--color-white);
		background-color: transparent;
		transition: background-color 0.25s ease;
		margin-bottom: -15rem;
		@include mq('lg', max) {
			margin-bottom: -7rem;
		}
	}
	.Header_container {
		height: var(--header-height--xs);
		padding-top: 3rem;
		@include mq('lg') {
			padding-top: 0;
			height: 15rem;
		}
	}
	.Header_scrolled {
		background-color: var(--color-background-default);
		box-shadow: 0 0 0.05rem 0.1rem var(--color-gradient--70);
		height: 10rem;
		@include mq('lg') {
			height: 13rem;
		}
	}
	.Header_navBackground {
		background: var(--color-background-nav_open);
	}

	.Header_navToggle {
		background-color: var(--color-sky-blue);
		&___isOpen {
			background-color: var(--color-sky-blue);
		}
	}
	.Header_imageOnly {
		padding-top: 3rem;
		@include mq('lg') {
			padding-top: 0;
		}
	}
}

:global(.Sydbank-theme) {
	.Header_container {
		min-height: var(--header-height--xs);
		height: 10rem;
		@include mq('lg') {
			height: 15rem;
		}
	}

	.Header_nav {
		margin-top: 2rem;
		@include mq('xl') {
			nav {
				margin-right: 0;
			}
		}
	}

	.Header_navBackground {
		background: var(--color-emperor);
	}

	.Header_navToggle {
		background-color: transparent;
		&___isOpen {
			background-color: var(--color-sky-blue);
		}
	}

	.Header_scrolled {
		height: 10rem;
		box-shadow: 0 0 0.05rem 0.1rem var(--color-background-contrast-dark);
		@include mq('lg') {
			height: 15rem;
		}
		.Header_imageOnly {
			padding-top: 0rem;
		}
	}

	.Header_imageOnly {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 11rem;
		max-width: 11rem;
		@include mq('lg') {
			// padding-top: 0;
			display: none;
		}
	}
}

//:global(.Dark-theme--masterCardPage) {
:global(.Dark-theme) {
	.Header {
		box-shadow: none;
		// color: var(--color-white);
		color: var(--color-white);
		// background-color: var(--color-background);
		background-color: transparent;
		transition: background-color 0.25s ease;
		margin-bottom: -15rem;
		@include mq('lg', max) {
			margin-bottom: -7rem;
		}
	}
	.Header_container {
		height: var(--header-height--xs);
		padding-top: 3rem;
		@include mq('lg') {
			padding-top: 0;
			height: 15rem;
		}
	}

	.Header_scrolled {
		background-color: var(--color-background-default);
		box-shadow: 0 0 0.05rem 0.1rem var(--color-gradient--70);
		height: 10rem;
		@include mq('lg') {
			height: 13rem;
		}
	}
	.Header_nav {
		@include mq('xl') {
			nav {
				margin: 0 20px;
			}
		}
		&___isOpen {
			background-color: var(--color-background);
		}
	}
	.Header_navBackground {
		background-color: var(--color-background);
	}

	.Header_navToggle {
		margin-bottom: 20px;
		z-index: 1;
	}

	.Header_imageOnly {
		padding-top: 3rem;
		@include mq('lg') {
			padding-top: 0;
		}
	}
}
