@import 'styles/2-tools/tools.typography-mixins.scss';

.CallBackForm {
	margin: var(--spacing--2xl) auto;
}

.CallBackForm_dateSelect {
	margin: 0;
}

.CallBackForm_submitButton {
	font-size: var(--font-size--sm);
	display: flex;
	justify-content: center;
}

.CallBackForm_title {
	margin-bottom: var(--spacing--md);
}
