@import 'styles/2-tools/tools.typography-mixins.scss';
@import 'styles/2-tools/tools.media-query.scss';

@mixin spacingGap($gap, $verticalGap) {
	.FormfieldGroup_fieldset > div {
		margin-top: $verticalGap;
		margin-left: $gap;
		margin-right: $gap;
	}
	margin-left: calc(#{$gap} * -1);
	margin-right: calc(#{$gap} * -1);
}
.FormfieldGroup {
	margin-top: var(--spacing--2xl);
	margin-bottom: var(--spacing--2xl);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
	&___inline {
		@include spacingGap(var(--spacing--2xs), var(--spacing--xs));
	}
	&___inline-lg {
		@include spacingGap(var(--spacing--xs), var(--spacing--sm));
	}

	&___blocked {
		@include spacingGap(var(--spacing--base), var(--spacing--base));
		background-color: var(--color-background-contrast);
		padding: var(--spacing--2xl) var(--spacing--base);
		margin-left: 0;
		margin-right: 0;
		@include mq('md', max) {
			padding: var(--spacing--base) var(--spacing--xs);
		}
	}

	&___blocked-no-label {
		background-color: var(--color-background-contrast);
		padding: var(--spacing--2xl) var(--spacing--base);
		margin: 0;
	}
}

.FormfieldGroup_fieldset {
	border: none;
	display: flex;
	flex-wrap: wrap;

	.FormfieldGroup___threeColumns & {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: var(--spacing--xs);
		padding: var(--spacing--2xs) 0;
	}

	.FormfieldGroup___blocked-no-label & {
		flex-direction: row;
		justify-content: space-between;
	}
}
.FormfieldGroup_label {
	font-weight: var(--font-weight--medium);
	margin-bottom: var(--spacing--2xs);
	position: relative;
	.FormfieldGroup___inline & {
		margin-left: var(--spacing--2xs);
		margin-bottom: var(--spacing--base);
	}
	.FormfieldGroup___inline-lg & {
		margin-left: var(--spacing--xs);
	}

	.FormfieldGroup___blocked & {
		margin-left: var(--spacing--base);
	}
}

:global(.Dark-theme) {
	.FormfieldGroup___blocked {
		color: var(--color-white);
		margin-top: 0;
		background-color: var(--color-background);
		padding: var(--spacing--lg) var(--spacing--2xs) var(--spacing--3xl);
	}
	.FormfieldGroup_label {
		font-size: var(--font-size--lg);
	}
}

.FormfieldGroup_helpCta {
	position: absolute;
	top: 0;
	right: -3rem;
	white-space: pre-line;
}

:global(.Sydbank-theme) {
	.FormfieldGroup {
		legend {
			@include mq('md', max) {
				font-size: var(--font-size--md);
			}

			@include mq('md') {
				font-size: var(--font-size--lg);
			}
		}
	}

	.FormfieldGroup___blocked {
		background-color: #f9f9f9;
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.FormfieldGroup {
		margin-bottom: var(--spacing--md);

		legend {
			@include mq('md', max) {
				font-size: var(--font-size--md);
			}

			@include mq('md') {
				font-size: var(--font-size--lg);
			}
		}
	}

	.FormfieldGroup_fieldset > div {
		margin-left: 0;
	}

	.FormfieldGroup___blocked {
		background-color: var(--color-dove-gray);
		padding: var(--spacing--lg) var(--spacing--4xl) var(--spacing--3xl);
	}

	.FormfieldGroup_label {
		margin-left: 0;
	}
}
