@import 'styles/2-tools/tools.media-query';
.MasterCardReceipt {
	width: 100%;
}
.MasterCardReceipt_disclaimer {
	@include mq('md') {
		margin-top: var(--spacing--base);
	}
	@include mq('md', max) {
		margin-bottom: var(--spacing--4xl);
	}
}

.MasterCardReceipt_priceDetailsList {
	> dl {
		div:last-child {
			margin-top: var(--spacing--4xl);
		}
	}
}

:global(.Dark-theme) {
	.MasterCardReceipt_disclaimer {
		margin: -0.1rem auto;
		background-color: var(--color-background-lighter);
		max-width: var(--content-size--default);
		@include mq('lg') {
			padding-top: var(--spacing--base);
			padding-bottom: var(--spacing--2xl);
		}
		@include mq('lg', max) {
			padding-top: var(--spacing--lg);
			padding-bottom: var(--spacing--4xl);
		}
	}
	.MasterCardReceipt_info {
		max-width: var(--content-size--default);
		background-color: var(--color-background-lighter);
		margin: 0 auto;
		padding: var(--spacing--md);
		@include mq('lg') {
			padding: var(--spacing--4xl);
		}
	}
	.MasterCardReceipt_list {
		margin-top: 0;
		@include mq('lg') {
			padding-top: var(--spacing--3xl);
		}
		@include mq('lg', max) {
			padding-top: var(--spacing--lg);
		}
	}
}

:global(.Sydbank-theme) {
	.MasterCardReceipt_list dl div,
	.MasterCardReceipt_priceDetailsList div:not(:last-child) {
		background-position: 0 calc(100% - 2.5rem);

		@include mq('md', max) {
			background: none;
			gap: var(--spacing--2xl);
		}

		dt {
			@include mq('md', max) {
				font-size: var(--font-size--xs);
			}

			@include mq('md') {
				font-size: var(--font-size--sm);
			}
		}

		dd {
			line-height: var(--line-height--2xl);

			@include mq('md', max) {
				font-size: var(--font-size--xs);
			}

			@include mq('md') {
				font-size: var(--font-size--sm);
			}

			> span {
				@include mq('md', max) {
					font-size: var(--font-size--xs);
				}

				@include mq('md') {
					font-size: var(--font-size--sm);
				}
			}
		}
	}

	.MasterCardReceipt_disclaimer {
		padding: 0 var(--spacing--md);
		margin-top: 0;
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.MasterCardReceipt_list dl div,
	.MasterCardReceipt_priceDetailsList div:not(:last-child) {
		background-position: 0 calc(100% - 2.5rem);

		@include mq('md', max) {
			background: none;
			gap: var(--spacing--2xl);
		}

		dt {
			@include mq('md', max) {
				font-size: var(--font-size--xs);
			}

			@include mq('md') {
				font-size: var(--font-size--sm);
			}
		}

		dd {
			line-height: var(--line-height--2xl);

			@include mq('md', max) {
				font-size: var(--font-size--xs);
			}

			@include mq('md') {
				font-size: var(--font-size--sm);
			}

			> span {
				@include mq('md', max) {
					font-size: var(--font-size--xs);
				}

				@include mq('md') {
					font-size: var(--font-size--sm);
				}
			}
		}
	}

	.MasterCardReceipt_container {
		margin-bottom: var(--spacing--5-5xl);
	}
}
