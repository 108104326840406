@import 'styles/2-tools/tools.borders.scss';
@import 'styles/2-tools/tools.typography-mixins.scss';
@import 'styles/2-tools/tools.keyboard-focus.scss';

.AccordionItem {
	-webkit-tap-highlight-color: transparent;
}

.AccordionItem_trigger {
	min-height: 6rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: var(--font-size--xl);
	width: 100%;
	outline: none;
	padding: 0;
	background-color: inherit;
	@include border-default(transparent);

	&:focus {
		@include keyboard-focus {
			@include border-default(var(--color-black));
		}
	}

	&::-moz-focus-inner {
		border: 0;
	}
}

.AccordionItem_title {
	width: 100%;
	display: flex;
	justify-content: space-between;
	pointer-events: none;
	padding: var(--spacing--sm) var(--spacing--base);
	line-height: var(--line-height--md);
	@include heading--xs();
	text-align: left;
}

.AccordionItem_icon {
	display: flex;
	align-items: center;
	pointer-events: none;

	span {
		display: block;
		border-bottom: var(--border--md) solid var(--color-gradient--80);
		width: 1.6rem;
	}

	span:first-child {
		position: absolute;
		transform: rotate(90deg);

		.AccordionItem_trigger[aria-expanded='true'] & {
			display: none;
		}
	}

	.AccordionItem_trigger:focus &,
	.AccordionItem_trigger:hover & {
		border-color: var(--color-primary);
	}
}

.AccordionItem_panel {
	margin: 0;
	padding: var(--spacing--xs) var(--spacing--base) var(--spacing--xl) var(--spacing--base);

	font-size: 50px;

	/* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/4806035/ */
	&[hidden] {
		display: none;
	}
}

.AccordionItem_content *:last-child {
	margin-bottom: 0;
}

:global(.Erhvervssikring-theme) {
	.AccordionItem {
		background-color: var(--color-functional-gray-blue-25);
	}

	.AccordionItem_panel {
		padding-top: 0.3rem;
	}
}

:global(.Codan-theme) {
	.AccordionItem_title {
		font-weight: var(--font-weight-semibold);
	}

	.AccordionItem_trigger {
		color: var(--color-primary);
	}

	.AccordionItem_icon {
		span {
			border-bottom: var(--border--md) solid var(--color-primary);
		}
	}
}

:global(.Sydbank-theme) {
	.AccordionItem_trigger {
		font-size: var(--font-size--md);
	}

	.AccordionItem_title {
		font-weight: var(--font-weight--normal);
	}
	.AccordionItem_icon {
		span {
			position: relative;
			border-bottom: none;
			&:after {
				position: absolute;
				content: '';
				right: 4px;
				transition: transform 0.1s cubic-bezier(0.55, 0.085, 0.68, 0.53);
				top: -10px;
				animation: none;
				opacity: 1;
				height: 16px;
				width: 16px;
				border-right: 2px solid var(--color-sky-blue);
				border-top: 2px solid var(--color-sky-blue);
				transform: rotate(135deg);
				background-color: transparent;
			}
		}

		span:first-child {
			display: none;
		}
	}

	.AccordionItem_trigger[aria-expanded='true'] .AccordionItem_icon span::after {
		transform: rotate(-45deg);
	}
}
