.ContentSection {
	// Padding:
	&___basePadding {
		padding-top: 0.01rem;
		padding-bottom: 0.01rem;
	}
	&___paddingTopLarge {
		padding-top: var(--spacing--5-5xl);
	}
	&___paddingBottomLarge {
		padding-bottom: var(--spacing--5-5xl);
	}

	// Background color:
	&___background {
		transform: translateY(-0.1rem); // Added to avoid small line between the bordering components
		&-white {
			background-color: var(--color-white);
		}
		&-business-green {
			background-color: var(--color-business-green);
		}
		&-business-light-green {
			background-color: var(--color-business-light-green);
		}
		&-functional-dark-blue {
			background-color: var(--color-functional-dark-blue);
		}
		&-functional-gray-blue {
			background-color: var(--color-functional-gray-blue);
		}
		&-functional-gray-blue50 {
			background-color: var(--color-functional-gray-blue-50);
		}
		&-functional-gray-blue25 {
			background-color: var(--color-functional-gray-blue-25);
		}
		&-functional-light-blue {
			background-color: var(--color-functional-light-blue);
		}
		&-functional-black {
			background-color: var(--color-functional-black);
		}
		&-business-medium-gray {
			background-color: var(--color-business-medium-gray);
		}
		&-business-medium-gray50 {
			background-color: var(--color-business-medium-gray-50);
		}
		&-business-medium-gray25 {
			background-color: var(--color-business-medium-gray-25);
		}
		&-business-light-gray {
			background-color: var(--color-business-light-gray);
		}
	}
}
