@import 'styles/2-tools/tools.display-mixins';
@import 'styles/2-tools/tools.media-query.scss';

.Logo {
	display: inline-block;
	text-decoration: none;
	color: currentColor;
}

.Logo_icon {
	display: none;
}

.Logo_description {
	margin-top: var(--spacing--md);
	font-size: var(--font-size--xl);
	font-weight: var(--font-weight--medium);
	max-width: 37rem;
}

.Logo_description___noMargin {
	margin-top: 0;
}

// .Logo___margined {
// 	margin-left: var(--spacing--md);
// 	@include mq('lg') {
// 		margin-left: var(--spacing--2xl);
// 	}

// }

:global(.Privatsikring-theme) {
	.Logo_asset___Psk {
		@include mq('lg') {
			width: 17.5rem;
			height: 3rem;
		}

		@include mq('lg', max) {
			width: 15.5rem;
			height: 2.7rem;
		}

		.Logo_icon {
			display: block;
		}
	}

	.Logo___Light {
		.Logo_icon___Dark {
			display: none;
		}
	}

	.Logo___Dark {
		.Logo_icon___Light {
			display: none;
		}
	}

	.Logo___enforceDarkStyleForDesktop {
		@include mq('lg') {
			.Logo_asset___Psk {
				.Logo_icon___Dark {
					display: block;
				}

				.Logo_icon___Light {
					display: none;
				}
			}
		}
	}
}

:global(.Erhvervssikring-theme) {
	.Logo_asset___Esk {
		width: 17.4rem;
		height: 2.4rem;

		.Logo_icon {
			display: block;
		}
	}

	.Logo___Light {
		.Logo_icon___Dark {
			display: none;
		}
	}

	.Logo___Dark {
		.Logo_icon___Light {
			display: none;
		}
	}
}

:global(.Codan-theme) {
	.Logo_description p {
		font-size: var(--font-size--xl);
	}

	.Logo_asset___Codan {
		width: 14.4rem;
		height: 3rem;

		.Logo_icon {
			display: block;
		}
	}

	.Logo_link {
		@include mq('lg') {
			height: var(--header-height--lg);
		}

		@include mq('lg', max) {
			height: var(--header-height--xs);
		}
	}

	.Logo___Light {
		.Logo_icon___Dark {
			display: none;
		}
	}

	.Logo___Dark {
		.Logo_icon___Light {
			display: none;
		}
	}

	.Logo___enforceDarkStyleForDesktop {
		@include mq('lg') {
			.Logo_asset___Codan {
				.Logo_icon___Dark {
					display: block;
				}

				.Logo_icon___Light {
					display: none;
				}
			}
		}
	}
}

:global(.Dark-theme--masterCardPage) {
	.Logo_asset___WorldElite {
		@include mq('lg') {
			width: 17.5rem;
			height: 3rem;
		}

		@include mq('lg', max) {
			width: 15.5rem;
			height: 2.7rem;
		}

		.Logo_icon {
			display: block;
		}
	}
}

:global(.Sydbank-theme) {
	.Logo_asset___Syd {
		// TODO KLK perhaps if SVG
		// display: block;
		// width: 7rem;
		// margin-left: 2px;
		// margin-top: -4px;
		.Logo_icon {
			// Png
			display: block;
			width: 7rem;
			margin-top: -4px;
		}
	}
	&.Dark-theme {
		.Logo___Light {
			.Logo_icon___Dark {
				display: none;
			}
		}

		.Logo___Dark {
			.Logo_icon___Light {
				display: none;
			}
		}
	}
}
