.Layout {
	font-family: var(--font-primary);
}

:global(.Dark-theme) {
	.Layout {
		display: block;
		background: url('../../../../public/media/WorldEliteBG.png') no-repeat var(--color-background-lighter);
		background-size: cover;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.Layout {
		display: block;
		background: transparent;
		background-size: cover;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
}
