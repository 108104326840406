@import 'styles/2-tools/tools.action.scss';
@import 'styles/2-tools/tools.typography-mixins.scss';

.FormfieldString {
	position: relative;
	display: flex;
	flex-flow: column;

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}

.FormfieldString_input {
	@include input;
	margin: var(--spacing--2xs) 0;
	width: 100%;
	border: var(--border--sm) solid var(--color-border--light);
	background: var(--color-white);
	-webkit-appearance: none;

	&::placeholder {
		color: currentColor;
	}

	.isDisabled & {
		cursor: not-allowed;
	}

	&[type='number'] {
		-moz-appearance: textfield;
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	&[type='date'] {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			right: var(--spacing--base);
			width: var(--icon-size--lg);
			height: 2.5rem;
			z-index: 0;
			background-repeat: no-repeat;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyOSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI4IDExLjAwMDFDMjggMTEuMDAwMSAyOCA0LjUwNDM2IDI4IDMuMDAwMDlDMjggMS40OTU4MSAyNyAxLjAwMDMgMjUuNSAxLjAwMDA5QzI0IDAuOTk5ODY4IDYuNDk5NTEgMS4wMDAzOSA0LjUgMS4wMDAwOUMyLjUwMDQ5IDAuOTk5NzgzIDEgMS4wMDAwOSAxIDQuMDAwMDhDMSA3LjAwMDA4IDEgMTQuNTExNiAxIDE2LjUwMDFDMSAxOC40ODg2IDEgMjAuMDAwMSAzLjUgMjAuMDAwMUM2IDIwLjAwMDEgMjMuNDk5NSAyMC4wMTE2IDI1LjUgMjAuMDAwMUMyNy41MDA1IDE5Ljk4ODYgMjggMTguOTg4NiAyOCAxNi41MDAxIiBzdHJva2U9IiM1MDUyNUMiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxjaXJjbGUgY3g9IjkiIGN5PSI0IiByPSIxIiBmaWxsPSIjNTA1MjVDIi8+CjxjaXJjbGUgY3g9IjIwIiBjeT0iNCIgcj0iMSIgZmlsbD0iIzUwNTI1QyIvPgo8Y2lyY2xlIGN4PSI3IiBjeT0iMTEiIHI9IjEiIGZpbGw9IiM1MDUyNUMiLz4KPGNpcmNsZSBjeD0iNyIgY3k9IjE1IiByPSIxIiBmaWxsPSIjNTA1MjVDIi8+CjxjaXJjbGUgY3g9IjEyIiBjeT0iMTEiIHI9IjEiIGZpbGw9IiM1MDUyNUMiLz4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxNSIgcj0iMSIgZmlsbD0iI0YzQTgzQiIvPgo8Y2lyY2xlIGN4PSIxNyIgY3k9IjExIiByPSIxIiBmaWxsPSIjNTA1MjVDIi8+CjxjaXJjbGUgY3g9IjE3IiBjeT0iMTUiIHI9IjEiIGZpbGw9IiM1MDUyNUMiLz4KPGNpcmNsZSBjeD0iMjIiIGN5PSIxMSIgcj0iMSIgZmlsbD0iIzUwNTI1QyIvPgo8L3N2Zz4K');
		}
		&::-webkit-calendar-picker-indicator {
			position: absolute;
			right: var(--spacing--base);
			width: var(--icon-size--lg);
			height: 2.5rem;
			opacity: 0;
			z-index: 1;
		}
	}
}

.FormfieldString_label {
	width: 100%;
	display: flex;
	min-height: 3rem;
	align-items: center;
}

.FormfieldString_labelText {
	@include formfield-label;
	width: unset;
	margin-right: var(--spacing--xs);
	padding: 0;
}

.FormfieldString_input:not([disabled]):hover,
.FormfieldString_label:hover ~ .FormfieldString_input:not([disabled]),
.FormfieldString_input:focus {
	border-color: var(--color-tertiary);
}

:global(.Dark-theme) {
	.FormfieldString_input {
		background: var(--color-background-lightest);
		border-color: var(--color-background-lightest);
		color: var(--color-white);
		&[type='date']:after {
			background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyOSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI4IDExLjAwMDFDMjggMTEuMDAwMSAyOCA0LjUwNDM2IDI4IDMuMDAwMDlDMjggMS40OTU4MSAyNyAxLjAwMDMgMjUuNSAxLjAwMDA5QzI0IDAuOTk5ODY4IDYuNDk5NTEgMS4wMDAzOSA0LjUgMS4wMDAwOUMyLjUwMDQ5IDAuOTk5NzgzIDEgMS4wMDAwOSAxIDQuMDAwMDhDMSA3LjAwMDA4IDEgMTQuNTExNiAxIDE2LjUwMDFDMSAxOC40ODg2IDEgMjAuMDAwMSAzLjUgMjAuMDAwMUM2IDIwLjAwMDEgMjMuNDk5NSAyMC4wMTE2IDI1LjUgMjAuMDAwMUMyNy41MDA1IDE5Ljk4ODYgMjggMTguOTg4NiAyOCAxNi41MDAxIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8Y2lyY2xlIGN4PSI5IiBjeT0iNCIgcj0iMSIgZmlsbD0id2hpdGUiLz4KPGNpcmNsZSBjeD0iMjAiIGN5PSI0IiByPSIxIiBmaWxsPSJ3aGl0ZSIvPgo8Y2lyY2xlIGN4PSI3IiBjeT0iMTEiIHI9IjEiIGZpbGw9IndoaXRlIi8+CjxjaXJjbGUgY3g9IjciIGN5PSIxNSIgcj0iMSIgZmlsbD0id2hpdGUiLz4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMSIgcj0iMSIgZmlsbD0id2hpdGUiLz4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxNSIgcj0iMSIgZmlsbD0iI0YzQTgzQiIvPgo8Y2lyY2xlIGN4PSIxNyIgY3k9IjExIiByPSIxIiBmaWxsPSJ3aGl0ZSIvPgo8Y2lyY2xlIGN4PSIxNyIgY3k9IjE1IiByPSIxIiBmaWxsPSJ3aGl0ZSIvPgo8Y2lyY2xlIGN4PSIyMiIgY3k9IjExIiByPSIxIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
		}
	}
	.FormfieldString_labelText {
		color: var(--color-white);
	}
}

.dawaAutocompleteSuggestions {
	margin: 0.3em 0 0 0;
	padding: 0;
	text-align: left;
	background: #ffffff;
	position: absolute;
	top: 90px;
	left: 0px;
	right: 0px;
	z-index: 9999;
	overflow-y: auto;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	max-height: 234px;
	width: 100%;
	border: 1px solid var(--color-tertiary);

	@include mq('md') {
		width: 100%;
		font-size: 1.3rem;
		line-height: 2rem;
	}
}

.dawaAutocompleteSuggestions_item {
	padding: var(--spacing--2xs) var(--spacing--base);
	margin: var(--spacing--2xs) 0;
	&:hover,
	&:focus {
		transition: all 100ms ease-in-out 0s;
		border-width: var(--border--sm);
		background-color: var(--color-tertiary);
		cursor: pointer;
		color: #ffffff;
	}
}

:global(.Sydbank-theme) {
	.FormfieldString_input {
		font-weight: var(--font-weight--light);
		background: var(--color-tint-blue);
		border-radius: 5px;
		border: 1px solid var(--color-sky-blue);
		&[type='date']:after {
			background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxNyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTE1Ljk2MDQgMS44Nzk5TDE1LjE2MzQgMS44ODI5N0wxMy4xOTMxIDEuODg5NjFWMEgxMS4zNTc1VjEuODk2MjVMNS41OTQxMyAxLjkxNjY4VjAuMDIxNDU1NEgzLjc1ODVWMS45MjMzMkwwLjg2OTQxOSAxLjkzMzU0SC0wLjAwMDk3NjU2MlYxOS45OTY5SDAuODg3Nzc2TDEuODM0NjYgMTkuOTkzOUwxNi4wNDkxIDE5Ljk0MzhIMTYuOTk5VjEuODc5OUgxNS45NjA0Wk0xNS4xNjM0IDE0LjI5OUwxMy4yMDY0IDE0LjMwNjFWMTIuMzM3OUwxNS4xNjM0IDEyLjMzMDdWMTQuMjk5Wk0xLjgzNDY2IDguNTY5OUwzLjc1ODUgOC41NjMyNlYxMC41MzJMMS44MzQ2NiAxMC41Mzg3VjguNTY5OVpNMTEuMzcwOCAxMC41MDVMOS4zODQ3MiAxMC41MTIxVjguNTQzMzNMMTEuMzcwOCA4LjUzNjE4VjEwLjUwNVpNNy41NDkwOCAxMC41MTg4TDUuNTk0MTMgMTAuNTI1NFY4LjU1NjYxTDcuNTQ5MDggOC41NDk5N1YxMC41MTg4Wk0xLjgzNDY2IDEyLjM3NzdMMy43NTg1IDEyLjM3MTFWMTQuMzM5NEwxLjgzNDY2IDE0LjM0NlYxMi4zNzc3Wk01LjU5NDEzIDEyLjM2NDRMNy41NDkwOCAxMi4zNTc4VjE0LjMyNjFMNS41OTQxMyAxNC4zMzI3VjEyLjM2NDRaTTkuMzg0NzIgMTIuMzUxMkwxMS4zNzA4IDEyLjM0NFYxNC4zMTIzTDkuMzg0NzIgMTQuMzE5NFYxMi4zNTEyWk0xNS4xNjM0IDEwLjQ5MTdMMTMuMjA2NCAxMC40OTg4VjguNTMwMDVMMTUuMTYzNCA4LjUyMjlWMTAuNDkxN1pNNS41OTQxMyA0Ljc3NzRWMy43NTU3MUwxMS4zNTc1IDMuNzM1MjhWNC43NTU5NEgxMy4xOTMxVjMuNzI4NjRMMTUuMTYzNCAzLjcyMlY2LjY4Mzg2TDEuODM0NjYgNi43MzA4NlYzLjc2OUwzLjc1ODUgMy43NjIzNlY0Ljc3NzRINS41OTQxM1pNMS44MzQ2NiAxNi4xODVMMy43NTg1IDE2LjE3ODRWMTguMTQ3N0wxLjgzNDY2IDE4LjE1NDNWMTYuMTg1Wk01LjU5NDEzIDE2LjE3MTdMNy41NDkwOCAxNi4xNjUxVjE4LjEzNDRMNS41OTQxMyAxOC4xNDE2VjE2LjE3MTdaTTkuMzg0NzIgMTYuMTU4NUwxMS4zNzA4IDE2LjE1MTNWMTguMTIxMUw5LjM4NDcyIDE4LjEyODNWMTYuMTU4NVpNMTMuMjA2NCAxNi4xNDUyTDE1LjE2MzQgMTYuMTM4VjE4LjEwNzhMMTMuMjA2NCAxOC4xMTQ1VjE2LjE0NTJaIiBmaWxsPSIjMDBCOUVBIi8+Cjwvc3ZnPgo=');
		}
	}

	.FormfieldString_input:not([disabled]):hover,
	.FormfieldString_label:hover ~ .FormfieldString_input:not([disabled]),
	.FormfieldString_input:focus {
		border-color: var(--color-sky-blue);
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.dawaAutocompleteSuggestions {
		color: #272a30;
	}
	.FormfieldString_input {
		font-weight: var(--font-weight--light);
		background: #272a30;
		border: 1px solid #272a30;

		&.hasError {
			color: var(--color-error);
		}
	}

	.FormfieldString_input:not([disabled]):hover,
	.FormfieldString_label:hover ~ .FormfieldString_input:not([disabled]),
	.FormfieldString_input:focus {
		border-color: #272a30;
	}

	::placeholder {
		opacity: 0.5; /* Firefox */
	}
}

:global(.Dark-theme) {
	.dawaAutocompleteSuggestions {
		color: #272a30;
	}
	.dawaAutocompleteSuggestions_item {
		&:hover,
		&:focus {
			background-color: var(--color-gradient--50);
		}
	}
}
