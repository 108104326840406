@import '../2-tools/tools.typography-mixins';

.Erhvervssikring-theme {
	.u-rte {
		&--heading {
			&-2xl {
				@include esk-heading--2xl;
			}

			&-xl {
				@include esk-heading--xl;
			}

			&-lg {
				@include esk-heading--lg;
			}
		}

		em {
			font-style: normal;
			color: var(--color-primary);
			display: inline-block;
		}
	}

	.u-rte-link-as-button {
		display: inline-block;
	}
}
