@mixin buttonCodan {
	&___primary {
		color: var(--color-dark-blue);
		background-color: var(--color-tertiary);
		font-weight: var(--font-weight--bold);

		&:hover {
			background-color: var(--color-hover-tertiary);
		}
	}
	&___secondary {
		color: var(--color-primary-dark);
		border-color: var(--color-primary-dark);

		&:hover {
			background-color: var(--color-athens-gray-dark);
		}
	}
	&___link {
		&:after,
		&:before {
			content: none;
		}
	}
}
