.PageLoader {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	height: 100vh;
}

.PageLoader_title {
	margin-top: var(--spacing--xs);
	line-height: var(--line-height--3xl);
	font-family: var(--font-primary);
	font-size: var(--line-height--md);
	font-weight: var(--font-weight--normal);
	color: var(--color-gradient--50);
}
