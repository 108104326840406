@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/2-tools/tools.aspect-ratio.scss';

.ContentTeaser {
	max-width: calc(var(--content-size--default) + var(--spacing--md) * 2);
	width: 100%;
	position: relative;
	display: flex;
	margin: var(--spacing--8xl) auto var(--spacing--9xl);
	padding: 0 var(--spacing--md);
	&___left {
		flex-direction: row;
	}

	&___right {
		flex-direction: row-reverse;
	}

	@include mq('md', max) {
		flex-direction: column;
		margin: var(--spacing--6xl) auto var(--spacing--7xl);
	}
}

.ContentTeaser_image {
	@include mq('md') {
		width: 72rem;
		height: 50rem;
	}
	@include mq('md', max) {
		@include aspect-ratio(16, 9);
		width: 100%;
		max-width: 100%;
	}
}

.ContentTeaser_container {
	position: absolute;
	width: 100%;
	max-width: 55rem;
	bottom: var(--spacing--md);
	background-color: var(--color-primary-light);
	color: var(--color-dark);
	padding: var(--spacing--8xl) var(--spacing--3xl) var(--spacing--3xl);

	&___left {
		right: var(--spacing--md);
	}

	&___right {
		left: var(--spacing--md);
	}
	&___white {
		background-color: var(--color-white);
	}

	@include mq('md', max) {
		position: unset;
		width: calc(100% - var(--spacing--lg));
		margin-top: calc(var(--spacing--5xl) * -1);
		z-index: 1;
		padding: var(--spacing--4xl) var(--spacing--lg);
	}
}

:global(.Privatsikring-theme) {
	.ContentTeaser_container___theme {
		background-color: var(--color-primary-light);
	}
}
:global(.Codan-theme) {
	.ContentTeaser_container___theme {
		background-color: var(--color-light-blue);
	}
}

.ContentTeaser_title {
	margin-bottom: var(--spacing--md);
	font-weight: var(--font-weight--medium);

	@include mq('md', max) {
		margin-bottom: var(--spacing--base);
	}
}

.ContentTeaser_body {
	margin-bottom: var(--spacing--2xl);

	@include mq('md', max) {
		margin-bottom: var(--spacing--lg);
	}
}
