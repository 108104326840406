.Spinner {
	--spinner-base-width: var(--icon-size--xl);
	--spinner-base-height: var(--icon-size--xl);
	--spinner-base-color: currentColor;
	width: var(--spinner-base-width);
	height: var(--spinner-base-height);

	&___primary {
		--spinner-base-color: var(--color-primary);
	}

	&___large {
		--spinner-base-width: var(--icon-size--2xl);
		--spinner-base-height: var(--icon-size--2xl);

		&.Spinner_circle {
			border-width: var(--border--2xl);
		}
	}

	&___small {
		--spinner-base-width: var(--icon-size--lg);
		--spinner-base-height: var(--icon-size--lg);

		&.Spinner_circle {
			border-width: var(--border--xl);
		}
	}

	&___slow {
		&.Spinner_circle {
			animation: Spinner_circle 1s linear infinite;
		}
		&.Spinner_dotted .Spinner_dotContainer div {
			animation: Spinner_dotted 2s infinite;
		}
	}

	&___error {
		--spinner-base-color: var(--color-error);

		&.Spinner_circle {
			border-top-color: var(--color-error);
			border-left-color: var(--color-error);
		}
	}
}

.Spinner_circle {
	border-top-color: var(--spinner-base-color);
	border-left-color: var(--spinner-base-color);
	animation: Spinner_circle 400ms linear infinite;
	border-bottom-color: transparent;
	border-right-color: transparent;
	border-style: solid;
	border-width: var(--border--md);
	border-radius: 50%;
	box-sizing: border-box;
	display: inline-block;
	vertical-align: middle;

	@keyframes Spinner_circle {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(359deg);
		}
	}
}

.Spinner_dotted {
	display: inline-block;
	overflow: hidden;

	.Spinner_dotContainer {
		width: 100%;
		height: 100%;
		position: relative;
		transform: translateZ(0) scale(0.54);
		backface-visibility: hidden;
		transform-origin: 0 0;

		& div {
			position: absolute;
			border-radius: 50%;
			width: calc(var(--spinner-base-width) * 0.25);
			height: calc(var(--spinner-base-height) * 0.25);
			animation: Spinner_dotted 1.1111111111111112s infinite;
			box-sizing: content-box;
		}

		.Spinner_dotFirst {
			left: calc(var(--spinner-base-width) * 0.33);
			background: var(--spinner-base-color);
			animation-delay: -0.6666666666666665s !important;
		}

		.Spinner_dotSecond {
			left: calc(var(--spinner-base-width) * 0.66);
			background: var(--spinner-base-color);
			animation-delay: -0.4444444444444444s !important;
		}

		.Spinner_dotThird {
			left: calc(var(--spinner-base-width) * 0.99);
			background: var(--spinner-base-color);
			animation-delay: -0.2222222222222222s !important;
		}
	}

	@keyframes Spinner_dotted {
		0% {
			animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
			top: calc(var(--spinner-base-height) * 0.9);
		}

		30% {
			animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
			top: calc(var(--spinner-base-height) * 0.6);
		}

		60% {
			animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5);
			top: calc(var(--spinner-base-height) * 0.9);
		}

		100% {
			top: calc(var(--spinner-base-height) * 0.9);
		}
	}
}

.Spinner_dottedCircle {
	width: var(--spinner-base-width);
	height: var(--spinner-base-height);
	display: inline-block;
	overflow: hidden;
	background: none;

	.Spinner_dotContainer {
		width: 100%;
		height: 100%;
		position: relative;
		transform: translateZ(0) scale(0.6);
		backface-visibility: hidden;
		transform-origin: 0 0; /* see note above */

		& div {
			box-sizing: content-box;
		}

		& div > div {
			position: absolute;
			width: calc(var(--spinner-base-width) * 0.133);
			height: calc(var(--spinner-base-height) * 0.133);
			border-radius: 50%;
			background: var(--spinner-base-color);
			animation: Spinner_dottedCircle 0.8333333333333334s linear infinite;
		}

		& div:nth-child(1) > div {
			left: calc(var(--spinner-base-width) * 1.2666);
			top: calc(var(--spinner-base-width) * 0.7666);
			animation-delay: -0.7291666666666667s;
		}
		& > div:nth-child(1) {
			transform: rotate(0deg);
			transform-origin: calc(var(--spinner-base-width) * 1.33) calc(var(--spinner-base-width) * 0.8333);
		}
		& div:nth-child(2) > div {
			left: calc(var(--spinner-base-width) * 1.1166);
			top: calc(var(--spinner-base-width) * 1.1166);
			animation-delay: -0.625s;
		}
		& > div:nth-child(2) {
			transform: rotate(45deg);
			transform-origin: calc(var(--spinner-base-width) * 1.18333) calc(var(--spinner-base-width) * 1.18333);
		}
		& div:nth-child(3) > div {
			left: calc(var(--spinner-base-width) * 0.7666);
			top: calc(var(--spinner-base-width) * 1.2666);
			animation-delay: -0.5208333333333334s;
		}
		& > div:nth-child(3) {
			transform: rotate(90deg);
			transform-origin: calc(var(--spinner-base-width) * 0.8333) calc(var(--spinner-base-width) * 1.33);
		}
		& div:nth-child(4) > div {
			left: calc(var(--spinner-base-width) * 0.41666);
			top: calc(var(--spinner-base-width) * 1.1166);
			animation-delay: -0.4166666666666667s;
		}
		& > div:nth-child(4) {
			transform: rotate(135deg);
			transform-origin: calc(var(--spinner-base-width) * 0.48333) calc(var(--spinner-base-width) * 1.18333);
		}
		& div:nth-child(5) > div {
			left: calc(var(--spinner-base-width) * 0.2666);
			top: calc(var(--spinner-base-width) * 0.7666);
			animation-delay: -0.3125s;
		}
		& > div:nth-child(5) {
			transform: rotate(180deg);
			transform-origin: calc(var(--spinner-base-width) * 0.333) calc(var(--spinner-base-width) * 0.8333);
		}
		& div:nth-child(6) > div {
			left: calc(var(--spinner-base-width) * 0.41666);
			top: calc(var(--spinner-base-width) * 0.41666);
			animation-delay: -0.20833333333333334s;
		}
		& > div:nth-child(6) {
			transform: rotate(225deg);
			transform-origin: calc(var(--spinner-base-width) * 0.48333) calc(var(--spinner-base-width) * 0.48333);
		}
		& div:nth-child(7) > div {
			left: calc(var(--spinner-base-width) * 0.7666);
			top: calc(var(--spinner-base-width) * 0.2666);
			animation-delay: -0.10416666666666667s;
		}
		& > div:nth-child(7) {
			transform: rotate(270deg);
			transform-origin: calc(var(--spinner-base-width) * 0.8333) calc(var(--spinner-base-width) * 0.333);
		}
		& div:nth-child(8) > div {
			left: calc(var(--spinner-base-width) * 1.1166);
			top: calc(var(--spinner-base-width) * 0.41666);
			animation-delay: 0s;
		}
		& > div:nth-child(8) {
			transform: rotate(315deg);
			transform-origin: calc(var(--spinner-base-width) * 1.18333) calc(var(--spinner-base-width) * 0.48333);
		}
	}

	@keyframes Spinner_dottedCircle {
		0% {
			opacity: 1;
			backface-visibility: hidden;
			transform: translateZ(0) scale(1.5, 1.5);
		}
		100% {
			opacity: 0;
			backface-visibility: hidden;
			transform: translateZ(0) scale(1, 1);
		}
	}
}

.Spinner_screenReader {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

/*
Does not work in Chrome at the moment

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
	.Spinner {
		display: none;
	}

	.Spinner_loadingMsg {
		position: relative;
		width: auto;
		height: auto;
		padding: var(--spacing--xs);
		overflow: hidden;
		clip: auto;
		white-space: nowrap;
		border: var(--border--lg) solid var(--color-error);
		color: var(--color-light);
		font-size: var(--font-size--lg);
		background: var(--color-error);
	}
}
*/
