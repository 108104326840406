@import 'styles/2-tools/tools.media-query';

.ProductPromo {
	margin-top: var(--spacing--7xl);
	margin-bottom: var(--spacing--7xl);
}

.ProductPromo_heading {
	max-width: 56rem;
	margin-bottom: var(--spacing--lg);

	@include mq('md') {
		margin-bottom: var(--spacing--2xl);
	}
}

.ProductPromo_text {
	max-width: 48rem;
	margin-bottom: var(--spacing--2xl);
}

.ProductPromo_link {
	display: inline-block;
	text-decoration: none;
	text-transform: uppercase;
	margin-bottom: var(--spacing--2xl);
}

:global(.Erhvervssikring-theme) {
	.ProductPromo_link {
		color: var(--color-functional-black);
		padding-left: 3.6rem;
		position: relative;
		font-weight: var(--font-weight--medium);

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			display: block;
			width: 2.6rem;
			height: 2px;
			background-color: var(--color-business-green);
		}
	}
}
