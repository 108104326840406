@import '../2-tools/tools.typography-mixins';

.Sydbank-theme {
	// Font size
	@include sydbank-paragraph;

	@include mq('md', max) {
		font-size: var(--font-size--xs);
	}

	--font-primary: Work Sans;
	--font-secondary: 'AlmBrand', serif;

	// Letter spacing
	--letter-spacing--sm: 0.15rem;
	--letter-spacing--md: 0.2rem;
}
