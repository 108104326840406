@import 'styles/2-tools/tools.media-query.scss';

.ProfileCtaContent {
	height: var(--spacing--2xl);
	display: flex;
	align-items: center;

	&_noArrow {
		@include mq('lg', max) {
			margin-left: 1.6rem;
		}
	}
}

.ProfileCtaContent_icon {
	display: flex;
	justify-content: center;
	margin-right: var(--spacing--2xs);
	height: var(--spacing--xl);
	width: var(--spacing--xl);
}

.ProfileCtaContent_title {
	position: relative;
	display: flex;
	align-items: center;
	font-size: var(--font-size--sm);
	border: var(--border--md) solid transparent;

	@include mq('lg', max) {
		font-size: var(--font-size--2xl);
		flex-direction: row-reverse;
	}
}

.ProfileCtaContent_titleIcon {
	width: var(--spacing--sm);
	height: var(--spacing--sm);
	margin-left: var(--spacing--2xs);
	transition: all 0.2s ease-in-out 0.2s;
	transform: rotate(270deg);

	@include mq('lg', max) {
		height: initial;
		margin-left: -0.8rem;
		margin-right: var(--spacing--xs);
	}

	.ProfileCtaContent___active & {
		transform: rotate(360deg);
	}
}

:global(.Privatsikring-theme) {
	.ProfileCtaContent_icon {
		background-color: var(--color-primary);
		border-radius: var(--spacing--xl);
	}

	.ProfileCtaContent_iconImage {
		filter: brightness(0) invert(1);
	}
}

:global(.Codan-theme) {
	.ProfileCtaContent_icon {
		background-color: var(--color-tertiary);
		border-radius: var(--spacing--xl);
		padding: var(--spacing--2xs);
	}

	.ProfileCtaContent_titleIcon {
		@include mq('lg', max) {
			filter: brightness(0) invert(1);
		}
	}
}
