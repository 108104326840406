@import 'styles/2-tools/tools.action.scss';
@import 'styles/2-tools/tools.typography-mixins.scss';

.FormfieldRadio {
	position: relative;
	display: flex;
	flex-flow: column;
	color: var(--color-gradient--80);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}

.FormfieldRadio_label {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;

	.isDisabled & {
		cursor: not-allowed;
	}

	&:before {
		content: '';
		display: block;
		width: 3rem;
		height: 3rem;
		background-color: var(--color-gradient--5);
		border-radius: 100%;
		margin-right: var(--spacing--xs);
		border: var(--border--sm) solid transparent;

		.hasError & {
			border-color: var(--color-error);
		}
	}

	&:after {
		content: '';
		display: block;
		width: 3rem * 0.45;
		height: 3rem * 0.45;
		background-color: transparent;
		border-radius: 100%;
		position: absolute;
		left: calc(3rem / 2);
		transform: translateX(-50%);
	}

	.FormfieldRadio_input:checked ~ &:after {
		background-color: var(--color-primary);
	}

	.FormfieldRadio___circle & {
		&:before {
			transition: background-color 0.2s ease-in-out;
			width: 4rem;
			height: 4rem;
		}
	}

	.FormfieldRadio___button & {
		display: inline-block;
		@include button;
		padding: var(--spacing--xs) var(--spacing--sm);
		@include paragraph--sm;
		border-color: var(--color-dark);
		min-width: auto;

		&:before,
		&:after {
			display: none;
		}
	}
}

.FormfieldRadio_input {
	@include input;
	width: 3rem;
	height: 3rem;
	border-radius: 100%;
	margin: var(--spacing--2xs) 0;
	position: absolute;
	opacity: 0;
	.FormfieldRadio___circle & {
		&:checked ~ label:after {
			background-color: transparent;
		}
		&:checked ~ label:before {
			background-color: var(--color-primary);
			border-color: transparent;
		}
		&:checked ~ label p {
			color: var(--color-white);
		}
	}

	.FormfieldRadio___button & {
		width: 100%;
		&:checked ~ .FormfieldRadio_label {
			background-color: var(--color-dark);
			color: var(--color-white);
		}
	}
}

.FormfieldRadio_input:focus ~ .FormfieldRadio_label:before,
.FormfieldRadio_input:not([disabled]) ~ .FormfieldRadio_label:hover:before {
	border-color: var(--color-tertiary);

	:global(.Sydbank-theme) & {
		border-color: var(--color-sky-blue);
	}
}

.FormfieldRadio_input:not([disabled]) ~ .FormfieldRadio_label:hover p,
.FormfieldRadio_input:not([disabled]) ~ .FormfieldRadio_label:focus p {
	.FormfieldRadio___circle & {
		color: var(--color-white);
	}
}
.FormfieldRadio_input:not([disabled]) ~ .FormfieldRadio_label:hover:before,
.FormfieldRadio_input:not([disabled]) ~ .FormfieldRadio_label:focus:before {
	.FormfieldRadio___circle & {
		border-color: transparent;
		background: var(--color-primary);
		color: var(--color-white);
	}
}

.FormfieldRadio___circle p {
	display: block;
	width: auto;
	height: 1.35rem;
	background-color: transparent;
	border-radius: 100%;
	position: absolute;
	top: 1.4rem;
	left: 2rem;
	transform: translateX(-50%);
}

// Rating buttons
.FormfieldRadio___star {
	input:focus ~ label:before,
	input:not([disabled]) ~ label:hover:before {
		border-color: transparent !important;
	}
	&:not(:checked) > label {
		float: right;
		width: 25px;
		padding-right: 5px;
		overflow: hidden;
		white-space: nowrap;
		cursor: pointer;
		font-size: 0;
		line-height: 25px;
		color: var(--color-gradient--5);
		transition: all 0.2s ease-in-out;
	}
	&:not(:checked) > label:before {
		content: '★';
		float: left;
		font-size: 30px;
		background-color: transparent;
	}
	& > input:checked ~ label {
		color: var(--color-primary);
	}

	&:not(:checked) > label:hover,
	&:not(:checked) > label:hover ~ label,
	&:not(:checked) > label:focus,
	&:not(:checked) > label:focus ~ label {
		color: var(--color-primary);
	}

	& > input:checked + label:hover,
	& > input:checked + label:hover ~ label,
	& > input:checked ~ label:hover,
	& > input:checked ~ label:hover ~ label,
	& > label:hover ~ input:checked ~ label,
	& > input:checked + label:focus,
	& > input:checked + label:focus ~ label,
	& > input:checked ~ label:focus,
	& > input:checked ~ label:focus ~ label,
	& > label:focus ~ input:checked ~ label {
		color: var(--color-primary);
	}
	& > label:active {
		position: relative;
	}
}

:global(.Sydbank-theme) {
	.FormfieldRadio {
		color: var(--color-primary);
	}

	.FormfieldRadio_label {
		&:before {
			content: '';
			display: block;
			width: 3rem;
			height: 3rem;
			background-color: var(--color-white);
			border: var(--border--sm) solid var(--color-sky-blue);
		}

		&:after {
			width: 0.9375rem;
			height: 1.5rem;
			transform: rotate(45deg) translate(50%, -50%);
			border: solid transparent;
			border-width: 0 var(--border--md) var(--border--md) 0;
			border-radius: 0;
			position: absolute;
			left: calc(var(--spacing--xs) / 10);
		}
	}
	.FormfieldRadio_input:checked ~ .FormfieldRadio_label:before {
		background-color: var(--color-sky-blue);
	}

	.FormfieldRadio_input:checked ~ .FormfieldRadio_label:after {
		background-color: transparent;
		border-color: var(--color-light);
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.FormfieldRadio {
		color: var(--color-white);
	}

	.FormfieldRadio_label {
		&:before {
			content: '';
			display: block;
			width: 3rem;
			height: 3rem;
			background-color: transparent;
			border: var(--border--sm) solid var(--color-sky-blue);
		}

		&:after {
			width: 0.9375rem;
			height: 1.5rem;
			transform: rotate(45deg) translate(50%, -50%);
			border: solid transparent;
			border-width: 0 var(--border--md) var(--border--md) 0;
			border-radius: 0;
			position: absolute;
			left: calc(var(--spacing--xs) / 10);
		}
	}
	.FormfieldRadio_input:checked ~ .FormfieldRadio_label:before {
		background-color: var(--color-sky-blue);
	}

	.FormfieldRadio_input:checked ~ .FormfieldRadio_label:after {
		background-color: transparent;
		border-color: var(--color-light);
	}
}
