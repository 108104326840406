@import 'styles/2-tools/tools.behavior.scss';

.FormfieldToggle {
	position: relative;
	display: flex;
	color: var(--color-gradient--80);
	margin-bottom: var(--spacing--xl);

	&.isDisabled,
	.isDisabled & {
		color: var(--color-gradient--5);
		.FormfieldToggle_label {
			cursor: not-allowed;
		}
	}
}

.FormfieldToggle_label {
	padding-left: calc(4rem + var(--spacing--2xs));
	line-height: var(--line-height--lg);
	position: relative;
	cursor: pointer;

	&:before {
		content: '';
		display: inline-block;
		background: var(--color-gradient--40);
		width: 4rem;
		height: calc(4rem / 2);
		left: 0;
		position: absolute;
		border-radius: 5rem;
		@include transition {
			transition: background-color 300ms ease-in-out;
		}

		.hasError & {
			border: var(--border--md) solid var(--color-error);
		}
	}

	.FormfieldToggle_control:checked ~ &:before {
		background: var(--color-primary);
	}

	.isDisabled & {
		&:before {
			background: currentColor;
		}
		&:after {
			opacity: 0.5;
		}
	}

	&:after {
		content: '';
		display: inline-block;
		background-color: var(--color-white);
		width: 1.4rem;
		height: 1.4rem;
		border-radius: var(--corner-circle);
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(0.2em, 0.2em);
		@include transition {
			transition: transform 300ms ease-in-out;
		}
	}

	.FormfieldToggle_control:checked ~ &:after {
		transform: translate(1.45em, 0.2em);
	}
}

.FormfieldToggle_control {
	width: 4rem;
	height: 4rem/2;
	position: absolute;
	opacity: 0;
}

.FormfieldToggle_labelInactive {
	&.hasError,
	.hasError & {
		color: var(--color-error);
	}
}

.FormfieldToggle_labelActive {
	display: none;
	&.hasError,
	.hasError & {
		color: var(--color-error);
	}
}

.FormfieldToggle_control:checked ~ .FormfieldToggle_label .FormfieldToggle_labelActive {
	display: block;
}

.FormfieldToggle_control:checked ~ .FormfieldToggle_label .FormfieldToggle_labelInactive {
	display: none;
}

:global(.Dark-theme--masterCardPage) {
	.FormfieldToggle {
		color: var(--color-white);
	}
}
