@import 'styles/2-tools/tools.typography-mixins.scss';
@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/50-themes/theme-erhvervssikring/2-tools/tools.typography-mixins.scss';
/**
	* In this molecule we style html elements directly
	* since they come from the RTE of a CMS or another editor
	* Optional additional styling for vanilla classes should be placed in utilities/rte.scss
*/
.RichText {
	h2 {
		@include heading--md;
		margin-bottom: var(--spacing--base);
	}

	h3 {
		@include heading--xs;
	}

	p {
		@include paragraph;
		margin-bottom: var(--spacing--md);
	}

	a {
		color: var(--color-black);

		&:hover,
		&:active {
			color: var(--color-tertiary);
		}
	}

	strong {
		font-weight: var(--font-weight--medium);
	}

	ol,
	ul {
		margin: var(--spacing--md) 0 var(--spacing--md) 1em;
	}

	li {
		padding-left: 1em;
		margin-top: var(--spacing--md);

		&:first-child {
			margin-top: 0;
		}

		&::marker {
			font-weight: var(--font-weight--semibold);
		}
	}
	&___light {
		a {
			color: var(--color-white);
		}
	}
	&___center {
		text-align: center;
	}
	&___petite {
		h2 {
			@include heading--sm;
		}
	}
}

:global(.Codan-theme) {
	.RichText {
		a:hover,
		a:active {
			color: var(--color-primary);
		}
	}
}

:global(.Privatsikring-theme) {
	.RichText___large {
		p {
			font-weight: var(--font-weight--light);
			@include mq('md', max) {
				font-size: var(--font-size--lg);
			}
			@include mq('md') {
				font-size: var(--font-size--xl);
			}
		}
	}
}

:global(.Sydbank-theme) {
	.RichText___large {
		p {
			font-weight: var(--font-weight--light);
			@include mq('md', max) {
				font-size: var(--font-size--lg);
			}
			@include mq('md') {
				font-size: var(--font-size--xl);
			}
		}
	}
}

:global(.Dark-theme) {
	.RichText {
		a {
			color: var(--color-neon-carrot);
		}
	}
}
:global(.Erhvervssikring-theme) {
	.RichText {
		h1 {
			@include esk-heading--2xl;
			margin-bottom: var(--spacing--sm);
		}

		h2 {
			@include esk-heading--xl;
			margin-bottom: var(--spacing--sm);
		}

		h3 {
			@include esk-heading--lg;
			margin-bottom: var(--spacing--sm);
		}

		p {
			@include esk-paragraph;
		}

		ol,
		ul {
			@include esk-paragraph;
			margin: var(--spacing--md) 0 var(--spacing--md) 0;
			list-style-position: inside;

			&:global(.u-rte-checkmark-list) {
				li {
					padding-left: 3.7rem;
					line-height: 1.5;

					&:not(:last-child) {
						margin-bottom: var(--spacing--sm);
					}

					&:before {
						left: var(--spacing--sm);
						top: var(--spacing--2xs);
					}
				}
			}
		}

		li {
			padding-left: 0;
			margin-top: 0;
			line-height: 2;
		}
	}
	.RichText___large {
		p {
			font-weight: var(--font-weight--light);
			@include mq('md', max) {
				font-size: var(--font-size--lg);
			}
			@include mq('md') {
				font-size: var(--font-size--xl);
			}
		}
	}
}
