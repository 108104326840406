.Erhvervssikring-theme {
	.u-background-color--white {
		background-color: var(--color-white);
	}
	.u-background-color--business-green {
		background-color: var(--color-business-green);
	}
	.u-background-color--business-light-green {
		background-color: var(--color-business-light-green);
	}
	.u-background-color--functional-dark-blue {
		background-color: var(--color-functional-dark-blue);
	}
	.u-background-color--functional-gray-blue {
		background-color: var(--color-functional-gray-blue);
	}
	.u-background-color--functional-gray-blue50 {
		background-color: var(--color-functional-gray-blue-50);
	}
	.u-background-color--functional-gray-blue25 {
		background-color: var(--color-functional-gray-blue-25);
	}
	.u-background-color--functional-light-blue {
		background-color: var(--color-functional-light-blue);
	}
	.u-background-color--functional-black {
		background-color: var(--color-functional-black);
	}
	.u-background-color--business-medium-gray {
		background-color: var(--color-business-medium-gray);
	}
	.u-background-color--business-medium-gray50 {
		background-color: var(--color-business-medium-gray-50);
	}
	.u-background-color--business-medium-gray25 {
		background-color: var(--color-business-medium-gray-25);
	}
	.u-background-color--business-light-gray {
		background-color: var(--color-business-light-gray);
	}
}
