@import 'styles/2-tools/tools.typography-mixins.scss';

.FormfieldSelect {
	position: relative;
	display: flex;
	flex-flow: column;

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}

.FormfieldSelect_label {
	width: 100%;
	display: flex;
	min-height: 3rem;
	align-items: center;
}

.FormfieldSelect_labelText {
	@include formfield-label;
	width: unset;
	margin-right: var(--spacing--xs);
	padding: 0;
}

.FormfieldSelect_wrapper {
	position: relative;
	margin: var(--spacing--2xs) 0;
	@include paragraph;

	&::placeholder {
		color: currentColor;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		right: var(--spacing--base);
		top: 50%;
		height: 1rem;
		width: 1rem;
		border: solid var(--color-dark);
		border-width: 0 0 var(--border--md) var(--border--md);
		background: transparent;
		pointer-events: none;
		transform: translateY(-66.7%) rotate(-45deg);
	}

	.hasError & {
		color: var(--color-error);

		.FormfieldSelect_element {
			border-color: var(--color-error);
		}
	}

	&.isMultiple {
		&:after,
		&:before {
			display: none;
		}

		.FormfieldSelect_element {
			padding-right: var(--spacing--base);
		}
	}
}

.FormfieldSelect_element {
	width: 100%;
	padding: var(--spacing--sm) var(--spacing--2xl) var(--spacing--sm) var(--spacing--base);
	border: var(--border--sm) solid var(--color-border--light);
	background: var(--color-white);
	appearance: none;
	cursor: pointer;
	outline: none;

	.isDisabled & {
		cursor: not-allowed;
	}

	&::-ms-expand {
		display: none;
	}

	&::placeholder {
		color: currentColor;
	}
}

.FormfieldSelect_label:focus,
.FormfieldSelect_label:hover {
	cursor: auto;
	& ~ .FormfieldSelect_wrapper .FormfieldSelect_element {
		border-color: transparent;
	}
}

.FormfieldSelect_element:focus,
.FormfieldSelect_element:not([disabled]):hover {
	border-color: var(--color-tertiary);
}

:global(.Dark-theme) {
	.FormfieldSelect_element {
		background: var(--color-background-lightest);
		border-color: var(--color-background-lightest);
	}
	.FormfieldSelect_wrapper:before {
		border-color: var(--color-white);
	}
}

:global(.Sydbank-theme) {
	.FormfieldSelect_wrapper {
		font-weight: var(--font-weight--light);

		&::before {
			height: 17px;
			width: 17px;
			border: solid var(--color-sky-blue);
			border-width: 0 0 var(--border--md) var(--border--md);
		}
	}

	.FormfieldSelect_element {
		background: var(--color-tint-blue);
		border-radius: 5px;
		border: 1px solid var(--color-sky-blue);
	}

	.FormfieldSelect_label:hover {
		cursor: auto;
		& ~ .FormfieldSelect_wrapper .FormfieldSelect_element {
			border-color: var(--color-sky-blue);
		}
	}

	.FormfieldSelect_element:focus,
	.FormfieldSelect_element:not([disabled]):hover {
		border-color: var(--color-sky-blue);
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.FormfieldSelect {
		margin-left: 0;
	}
	.FormfieldSelect_wrapper {
		font-weight: var(--font-weight--light);

		&::before {
			border-color: var(--color-white);
		}
	}

	.FormfieldSelect_element {
		background: #272a30;
		border: 1px solid #272a30;
	}
}
