@import 'styles/2-tools/tools.media-query.scss';

.MasterCardConditions {
	background-color: var(--color-background-contrast-light);

	@include mq('md') {
		padding-top: var(--spacing--3xl);
		padding-bottom: var(--spacing--4xl);
		margin: var(--spacing--4xl) auto var(--spacing--3xl);
	}
	@include mq('md', max) {
		padding-top: var(--spacing--3xl);
		padding-bottom: var(--spacing--6xl);
		margin: var(--spacing--5-5xl) auto;
	}
}

.MasterCardConditions_heading {
	font-size: var(--font-size--lg);
	font-weight: var(--font-weight--medium);
	@include mq('md') {
		margin-bottom: var(--spacing--lg);
	}
	@include mq('md', max) {
		margin-bottom: var(--spacing--md);
	}
}

.MasterCardConditions_text {
	@include mq('md') {
		margin-bottom: var(--spacing--2xl);
	}
	@include mq('md', max) {
		margin-bottom: var(--spacing--3xl);
	}
}

.MasterCardConditions_toggle {
	@include mq('md') {
		margin-bottom: var(--spacing--5xl);
	}
	@include mq('md', max) {
		margin-bottom: var(--spacing--2xl);
	}
}

:global(.Dark-theme) {
	.MasterCardConditions {
		background-color: transparent;
		padding: 0;
		margin: auto;
		color: var(--color-white);
	}
}

:global(.Sydbank-theme) {
	.MasterCardConditions {
		max-width: calc(var(--content-size--default) + var(--spacing--md) * 2);

		@include mq('md') {
			margin: 0 auto var(--spacing--3xl);
		}
		@include mq('md', max) {
			margin: 0 auto;
		}
	}
}
