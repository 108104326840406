.Erhvervssikring-theme {
	// Font family
	--font-primary: 'Poppins', sans-serif;

	// Font weights
	--font-weight--light: 300;
	--font-weight--normal: 400;
	--font-weight--medium: 500;
	--font-weight--semibold: 600;
	--font-weight--bold: 700;
	--font-weight--black: 800;
	--font-weight--extrablack: 900;

	// Font sizes
	--font-size--sm: 1.4rem;
	--font-size--md: 1.6rem;
	--font-size--lg: 1.8rem;
	--font-size--xl: 2rem;
	--font-size--2xl: 2.2rem;
	--font-size--3xl: 2.4rem;
	--font-size--4xl: 2.8rem;
	--font-size--5xl: 3.4rem;
	--font-size--6xl: 5rem;

	// Line heights
	--line-height--2xs: 1.16;
	--line-height--xs: 1.24;
	--line-height--sm: 1.25;
	--line-height--md: 1.27;
	--line-height--lg: 1.29;
	--line-height--xl: 1.4;
	--line-height--2xl: 1.44;
	--line-height--3xl: 1.5;
	--line-height--4xl: 1.57;

	// Letter spacing
	--letter-spacing--sm: 0rem;
	--letter-spacing--md: 0.1rem;
}
