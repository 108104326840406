@import 'styles/2-tools/tools.aspect-ratio.scss';

.ArticleMedia {
	display: block;
	margin: var(--spacing--3xl) auto;
	width: 100%;
	max-width: var(--content-size--sm);
}

.ArticleMedia_caption {
	font-style: italic;
	font-size: var(--font-size--sm);
	margin-top: var(--spacing--xs);
}

.ArticleMedia_mediabox {
	@include aspect-ratio(5, 2);
}
