@import 'styles/2-tools/tools.media-query.scss';
// @import "../../../../../styles/50-themes/theme-sydbank/1-settings/settings.spacing.scss";
// @import "styles/50-themes/theme-sydbank/1-settings/_settings.spacing.scss"
// @import "styles/50-themes/theme-sydbank/2-tools/tools.typography-mixins.scss";

.LinkCard {
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-direction: column;
	min-height: 26rem;
	position: relative;
	text-decoration: none;

	:global(.Sydbank-theme) & {
		min-height: 25rem;
	}
}

.LinkCard_background {
	background-color: var(--color-primary-light);
	position: absolute;
	z-index: -1;
	height: 75%;
	width: 93%;
}

.LinkCard_heading {
	color: var(--color-dark);
	padding: var(--spacing--md) var(--spacing--lg) 0;
	width: 93%;
	font-weight: var(--font-weight--medium);
}

.LinkCard_media {
	width: 26.3rem;
	height: 16.5rem;
	align-self: flex-end;
}
