@import 'styles/2-tools/tools.typography-mixins.scss';
@import 'styles/50-themes/theme-erhvervssikring/2-tools/tools.typography-mixins.scss';
@import 'styles/50-themes/theme-sydbank/2-tools/tools.typography-mixins.scss';

.Heading {
	&___xs {
		@include heading--xs;
	}

	&___sm {
		@include heading--sm;
	}

	&___md {
		@include heading--md;
	}

	&___lg {
		@include heading--lg;
	}

	&___xl {
		@include heading--xl;
	}

	&___2xl {
		@include heading--2xl;
	}
}

:global(.Erhvervssikring-theme) {
	.Heading {
		&___lg {
			@include esk-heading--lg;
		}

		&___xl {
			@include esk-heading--xl;
		}

		&___2xl {
			@include esk-heading--2xl;
		}
	}
}

:global(.Sydbank-theme) {
	.Heading {
		&___2xl {
			@include sydbank-heading--2xl;
		}
	}
}
