@import 'styles/2-tools/tools.media-query.scss';

.USPBlock {
	margin: var(--spacing--10-5xl) 0;
	display: flex;
	flex-direction: column;
	gap: var(--spacing--5-5xl);

	&___styleDark {
		color: var(--color-white);
	}
}

.USPBlock_itemsContainer {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--4xl);

	@include mq('lg') {
		gap: var(--spacing--5-5xl);
	}
}

.USPBlock_itemGrid {
	display: grid;
	grid-template-columns: 1fr;
	row-gap: var(--spacing--5-5xl);
	column-gap: var(--spacing--7xl);

	@include mq('lg') {
		grid-template-columns: repeat(2, 1fr);
		flex-direction: row;
	}

	.USPBlock___highlightFirstItem & {
		& > *:first-child {
			@include mq('lg') {
				grid-column: 1/3;
			}
		}
	}
}
