/* ------------------------------------ *\
		settings.colors
		
		Please use https://chir.ag/projects/name-that-color/
		to name colors
\* ------------------------------------ */

:root {
	// Base colors
	--color-black: #000000;
	--color-white: #ffffff;

	// Variations of gray named with the alpha percentage
	--color-gradient--5: #{darken(#f2f2f2, 5%)};
	--color-gradient--8: #{darken(#f2f2f2, 8%)};
	--color-gradient--10: #{darken(#f2f2f2, 10%)};
	--color-gradient--20: #{darken(#f2f2f2, 20%)};
	--color-gradient--30: #{darken(#f2f2f2, 30%)};
	--color-gradient--40: #{darken(#f2f2f2, 40%)};
	--color-gradient--50: #{darken(#f2f2f2, 50%)};
	--color-gradient--60: #{darken(#f2f2f2, 60%)};
	--color-gradient--70: #{darken(#f2f2f2, 70%)};
	--color-gradient--80: #{darken(#f2f2f2, 80%)};
	--color-gradient--90: #{darken(#f2f2f2, 90%)};
	--color-gradient--100: #{darken(#f2f2f2, 100%)};

	// Color palette generic
	--color-neon-carrot: #fea235;
	--color-dark-orange: #f18b0e;
	--color-mustard: #ffd755;
	--color-flesh: #ffd7a2;
	--color-texas-rose: #feb053;
	--color-broom: #ffe925;
	--color-salomie: #ffe180;
	--color-witch-haze: #fff492;

	--color-wisp-ping: #fdedf3;
	--color-narvik: #e8f7f0;
	--color-alto: #d0d0d0;
	--color-early-dawn: #fff9e7;
	--color-athens-gray: #f4f4f6;
	--color-athens-gray-light: #f8f8f9;
	--color-athens-gray-dark: #f2f1f4;
	--color-concrete: #f2f2f2;
	--color-gray-suit: #beb9c8;
	--color-red-ribbon: #e8004d;

	--color-mountain-meadow: #18b469;

	--color-blue-gem: #4014a9;

	--color-sky-blue: #00b9ea; // AB color-secondary
	--color-king-blue: #032846;
	--color-hover-blue: #5ac9fa;
	--color-tint-blue: #ebf6fb;
	--color-tint-blue2: #ebf6fc;
	--color-ab-gray: #eeeff1;
	--color-ab-primary: #052951;

	// Theme colors generic
	--color-primary: var(--color-neon-carrot);
	--color-primary-dark: var(--color-dark-orange);
	--color-primary-contrast: var(--color-texas-rose);
	--color-primary-light: var(--color-early-dawn);
	--color-primary-light-contrast: var(--color-flesh);
	--color-secondary: var(--color-mustard);
	--color-secondary-light: var(--color-salomie);
	--color-tertiary: var(--color-blue-gem);

	// Utilities colors generic
	--color-error: var(--color-red-ribbon);
	--color-error-contrast: var(--color-wisp-ping);
	--color-success: var(--color-mountain-meadow);
	--color-success-contrast: var(--color-narvik);
	--color-pending: var(--color-broom);
	--color-pending-contrast: var(--color-witch-haze); // to do change
	--color-dark: var(--color-black);
	--color-light: var(--color-concrete);
	--color-active: var(--color-primary);
	--color-inactive: var(--color-gradient--20);

	// Background colors generic
	--color-background-contrast: var(--color-athens-gray);
	--color-background-contrast-light: var(--color-athens-gray-light);
	--color-background-contrast-dark: var(--color-athens-gray-dark);

	// Border colors generic
	--color-border--dark: var(--color-dark);
	--color-border--gray: var(--color-gray-suit);
	--color-border--light: var(--color-alto);

	// Shadows generic
	--shadow-color--milder: rgba(#2c2c2c, 1);
	--shadow-color--mild: rgba(#2c2c2c, 0.2);
	--shadow-color--base: rgba(#2c2c2c, 0.5);
}
