@import 'styles/2-tools/tools.action.scss';
@import 'styles/50-themes/theme-codan/tools.action.scss';
.LinkButton {
	@include button;

	&___sm {
		font-size: var(--font-size--sm);
	}
}

:global(.Codan-theme) {
	.LinkButton {
		@include buttonCodan;
	}
}
