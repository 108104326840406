@mixin spacing($spacingValue) {
	width: calc(100% + #{$spacingValue});
	margin: calc(-1 * #{$spacingValue} / 2);

	> .u-grid__cell {
		padding: calc(#{$spacingValue} / 2);
	}
}

@mixin spacingHorizontal($spacingValue) {
	margin-left: calc(-1 * -#{$spacingValue} / 2);
	margin-right: calc(-1 * #{$spacingValue} / 2);

	> .u-grid__cell {
		padding-left: calc(#{$spacingValue} / 2);
		padding-right: calc(#{$spacingValue} / 2);
	}
}

@mixin spacingVertical($spacingValue) {
	margin-top: calc(-1 * #{$spacingValue} / 2);
	margin-bottom: calc(-1 * #{$spacingValue} / 2);

	> .u-grid__cell {
		padding-top: calc(#{$spacingValue} / 2);
		padding-bottom: calc(#{$spacingValue} / 2);
	}
}
