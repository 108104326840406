/* ===========================================
	utilities.container
	========================================= */
/**
	*  Utilities – utility- and helper classes
	*  with the ability to override anything
*/
@import 'src/styles/2-tools/tools.media-query.scss';

.Dark-theme {
	&.Sydbank-theme {
		.u-container {
			max-width: var(--content-size--default);

			@include mq('md') {
				padding: 0;
			}
		}

		.u-container--narrow {
			padding: var(--spacing--xl) var(--spacing--4xl);
		}
	}
}
