@import 'styles/2-tools/tools.action.scss';
@import 'styles/2-tools/tools.typography-mixins.scss';
@import 'styles/2-tools/tools.media-query.scss';

.FormfieldCheckbox {
	position: relative;
	display: flex;
	flex-flow: row;
	margin-bottom: var(--spacing--2xs);
	width: 100%;
	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
	&___button {
		display: inline-block;
	}
}

.FormfieldCheckbox_label {
	display: flex;
	cursor: pointer;
	position: relative;
	align-items: center;
	margin-right: var(--spacing--xs);
	width: unset;

	.isDisabled & {
		cursor: not-allowed;
	}

	.FormfieldCheckbox___multiline & {
		align-items: flex-start;
	}

	.FormfieldCheckbox___button & {
		@include button;
		padding: var(--spacing--xs) var(--spacing--sm);
		@include paragraph--sm;
		border-color: var(--color-dark);
		min-width: auto;
	}
	.FormfieldCheckbox_input:checked + & {
		.FormfieldCheckbox___button & {
			color: var(--color-white);
			background-color: var(--color-dark);
		}
	}

	&:before {
		content: '';
		display: block;
		width: 3rem;
		height: 3rem;
		background-color: var(--color-white);
		margin-right: var(--spacing--xs);
		flex-shrink: 0;
		border: var(--border--sm) solid var(--color-border--light);
		.FormfieldCheckbox_input:checked + & {
			border-color: transparent;
			background-color: var(--color-primary);
		}

		.hasError & {
			border-color: var(--color-error);
		}

		.FormfieldCheckbox___button & {
			content: none;
		}
	}

	&:after {
		content: '';
		display: block;
		width: calc(3rem / 3.2);
		height: calc(3rem / 2);
		transform: rotate(45deg) translate(50%, -50%);
		border: solid transparent;
		border-width: 0 var(--border--md) var(--border--md) 0;
		position: absolute;
		left: calc(var(--spacing--xs) / 10);
		@include mq('xs') {
			top: 0.6rem;
		}
		.FormfieldCheckbox_input:checked + & {
			border-color: var(--color-light);
		}
		.FormfieldCheckbox___button & {
			content: none;
		}
	}
}

.FormfieldCheckbox_input {
	@include input;
	width: 3rem;
	height: 3rem;
	margin: var(--spacing--2xs) 0;
	position: absolute;
	opacity: 0;
	&.hasError {
		color: var(--color-error);
	}
}

.FormfieldCheckbox_description {
	margin-top: var(--spacing--xs);
}

.FormfieldCheckbox_input:focus ~ .FormfieldCheckbox_label:before,
.FormfieldCheckbox_input:not([disabled]) ~ .FormfieldCheckbox_label:hover:before {
	border-color: var(--color-tertiary);
}

:global(.Dark-theme) {
	.FormfieldCheckbox_label:before {
		background: var(--color-background-lightest);
		border-color: var(--color-background-lightest);
	}
}

:global(.Sydbank-theme) {
	.FormfieldCheckbox_label {
		@include mq('md', max) {
			align-items: flex-start;
		}
	}

	.FormfieldCheckbox_label:before {
		border-color: var(--color-sky-blue);

		@include mq('md', max) {
			margin-top: 2px;
		}
	}

	.FormfieldCheckbox_label:after {
		width: 1rem;

		@include mq('md', max) {
			width: calc(3rem / 3.2);
			transform: rotate(45deg) translate(120%, -10%);
			top: auto;
		}

		@include mq('xs') {
			top: auto;
		}
	}

	.FormfieldCheckbox_input:checked + .FormfieldCheckbox_label:before {
		border-color: transparent;
		background-color: var(--color-sky-blue);
	}

	.FormfieldCheckbox_input:focus ~ .FormfieldCheckbox_label:before,
	.FormfieldCheckbox_input:not([disabled]) ~ .FormfieldCheckbox_label:hover:before {
		border-color: var(--color-sky-blue);
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.FormfieldCheckbox_label {
		@include mq('md', max) {
			align-items: flex-start;
		}
	}

	.FormfieldCheckbox_label:before {
		background: transparent;
		border-color: var(--color-sky-blue);

		@include mq('md', max) {
			margin-top: 2px;
		}
	}

	.FormfieldCheckbox_label:after {
		width: 1rem;

		@include mq('md', max) {
			width: calc(3rem / 3.2);
			transform: rotate(45deg) translate(120%, -10%);
			top: auto;
		}

		@include mq('xs') {
			top: auto;
		}
	}
}
