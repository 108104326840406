@import 'styles/2-tools/tools.display-mixins.scss';
@import 'styles/2-tools/tools.media-query.scss';

.FooterNavigation_list {
	width: 100%;
	list-style: none;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: var(--spacing--base);

	@include mq('md', max) {
		margin-top: var(--spacing--lg);
	}

	@include mq('xs', max) {
		grid-template-columns: 1fr;
		margin-left: var(--spacing--xs);
	}
}

.FooterNavigation_item {
	padding-left: var(--spacing--xs);
	position: relative;

	&:before {
		content: ' ';
		background-image: url('../../../../../../public/icons/arrow.png');
		background-repeat: no-repeat;
		background-position: center center;
		display: inline-block;
		position: absolute;
		top: 0.5rem;
		left: -1rem;
		width: 1rem;
		height: 1.3rem;
	}
}

.FooterNavigation_link {
	text-decoration: none;
	color: var(--color-dark);
	font-size: var(--font-size--md);
	font-weight: var(--font-weight--light);
}

.FooterNavigation_socialMediaLinks {
	list-style: none;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 3rem;
	margin-top: var(--spacing--4xl);

	@include mq('xs', max) {
		grid-template-columns: 1fr;
		margin-top: var(--spacing--3xl);
		margin-left: var(--spacing--base);
	}

	@include mq('md', max) {
		margin-left: var(--spacing--xs);
	}
}

.FooterNavigation_socialMediaItem {
	position: relative;
	margin-left: var(--spacing--base);
	padding-left: var(--spacing--base);
}

.FooterNavigation_socialMediaLink {
	text-decoration: none;
	color: var(--color-dark);
	font-size: var(--font-size--md);
	font-weight: var(--font-weight--light);
	background: none;
	border: none;
	padding: 0;
}

.FooterNavigation_socialMediaIcon {
	position: absolute;
	width: 4.4rem;
	height: 4.4rem;
	background: var(--color-white);
	border-radius: var(--corner-size--xl);
	padding: 0.5rem;
	top: -1rem;
	left: -4rem;
}

:global(.Privatsikring-theme) {
	.FooterNavigation_link,
	.FooterNavigation_socialMediaLink {
		&:hover,
		&:focus {
			text-decoration: none;
			color: var(--color-tertiary);
		}
	}
}

:global(.Codan-theme) {
	.FooterNavigation_link,
	.FooterNavigation_socialMediaLink {
		color: var(--color-white);
		&:hover,
		&:focus {
			text-decoration: underline;
		}
		&:active {
			text-decoration: none;
			font-weight: var(--font-weight--semibold);
		}
	}

	.FooterNavigation_item {
		&:before {
			background-image: url('../../../../../../public/icons/arrow-white.png');
		}
	}
}

:global(.Sydbank-theme) {
	.FooterNavigation_socialMediaLinks {
		list-style: none;
		display: flex;
		grid-template-columns: none;
		grid-gap: 0;
		margin-top: 16px;
	}
	.FooterNavigation_item {
		margin-left: var(--spacing--base);
		padding-left: var(--spacing--base);
		&:before {
			background-image: none;
		}
	}

	.FooterNavigation_socialMediaItem {
		&:nth-of-type(2) {
			margin-left: var(--spacing--xs);
			padding-left: var(--spacing--xs);
		}
	}

	.FooterNavigation_socialMediaLink {
		text-decoration: none;
		background: none;
		border: none;
		padding: 0;
	}
	.FooterNavigation_socialMediaIcon {
		position: relative;
		width: 4.4rem;
		height: 4.4rem;
		padding: 0;
		&:hover,
		&:focus {
			text-decoration: none;
			opacity: 0.6;
		}
	}
}
