.FormfieldHelpCta_icon {
	height: 2.5rem;
	width: 2.5rem;
	cursor: pointer;
	fill: var(--color-white);
	color: var(--color-black);
}

.FormfieldHelpCta_modalText {
	color: var(--color-black);
	padding-bottom: var(--spacing--base);
}

:global(.Dark-theme) {
	.FormfieldHelpCta_icon {
		fill: none;
		color: var(--color-white);
	}
}

:global(.Sydbank-theme) {
	.FormfieldHelpCta_icon {
		color: var(--color-primary);
	}
}
