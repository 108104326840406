@import 'styles/2-tools/tools.media-query';
.MasterCardUserForm {
	width: 100%;
}

.MasterCardUserForm_wrapper {
	background-color: var(--color-athens-gray-light);
	padding: var(--spacing--xl) var(--spacing--2xl) var(--spacing--5-5xl);
}

.MasterCardUserForm_grid {
	@include mq('md') {
		margin-top: var(--spacing--xl);
	}
	@include mq('md', max) {
		margin-top: var(--spacing--md);
	}
	&___sibling:not(:first-of-type) {
		margin-top: 0;
	}
}

.MasterCardUserForm_cta {
	@include mq('md') {
		margin-top: var(--spacing--9xl);
	}
	@include mq('md', max) {
		margin-top: var(--spacing--7xl);
	}
}

:global(.Dark-theme) {
	.MasterCardUserForm_wrapper {
		background-color: transparent;
		color: var(--color-white);
		margin-top: 0;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.MasterCardUserForm_dateField {
		input[type='date']::-webkit-datetime-edit-text,
		input[type='date']::-webkit-datetime-edit-month-field,
		input[type='date']::-webkit-datetime-edit-day-field,
		input[type='date']::-webkit-datetime-edit-year-field {
			opacity: 0.5;
		}

		&.hasValue {
			input[type='date']::-webkit-datetime-edit-text,
			input[type='date']::-webkit-datetime-edit-month-field,
			input[type='date']::-webkit-datetime-edit-day-field,
			input[type='date']::-webkit-datetime-edit-year-field {
				opacity: 1;
			}
		}
	}

	.MasterCardUserForm_wrapper {
		background-color: var(--color-dove-gray);
		padding-left: var(--spacing--4xl);
		padding-right: var(--spacing--4xl);
	}

	.MasterCardUserForm_grid {
		padding: var(--spacing--lg) 0 var(--spacing--3xl);

		&.MasterCardUserForm_grid_darkSydbank {
			margin-left: -10px;
		}

		@include mq('md') {
			margin-top: 0;
		}
	}

	.MasterCardUserForm_checkbox {
		margin-top: var(--spacing--base);
	}
}
