@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/2-tools/tools.typography-mixins.scss';
@import 'styles/50-themes/theme-erhvervssikring/2-tools/tools.typography-mixins.scss';

$offset-md: -13rem;
$offset-xs: -5rem;

.HeroCard {
	position: relative;
	height: 100%;
	min-height: 40rem;
	background: var(--color-primary);
	color: var(--color-dark);
	text-align: left;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: var(--spacing--lg);

	:global(.Erhvervssikring-theme) & {
		margin-bottom: 0;
	}

	&___minimal {
		min-height: 22rem;
		background-color: transparent;
		text-align: center;
	}

	&___simple {
		min-height: 18.4rem;

		@include mq('md') {
			min-height: 30rem;
		}
	}

	&___blocked {
		background-color: transparent;
		text-align: center;
		min-height: auto;
		:global(.Erhvervssikring-theme) & {
			text-align: left;
		}

		:global(.Sydbank-theme) & {
			@include mq('md') {
				max-width: 1146px;
			}
		}
	}

	&___offset {
		background-color: transparent;
		text-align: center;
		min-height: 0;

		@include mq('md') {
			margin-bottom: $offset-md;
		}

		@include mq('md', max) {
			margin-bottom: $offset-xs;
		}

		:global(.Erhvervssikring-theme) & {
			text-align: left;

			@include mq('lg') {
				text-align: center;
			}
		}
	}

	&___lightText {
		color: var(--color-light);
	}
}

.HeroCard_imageContainer {
	.HeroCard___minimal & {
		display: none;
	}

	.HeroCard___blocked &,
	.HeroCard___offset & {
		position: relative;

		@include mq('md') {
			height: 35rem;
		}

		@include mq('md', max) {
			height: 20rem;
		}
	}
}

.HeroCard_mediabox {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.HeroCard_textbox {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	margin: auto;
	max-width: 1024px;
	padding: var(--spacing--xl);

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	hr {
		width: 77px;
		border: 0;
		background-color: var(--color-sky-blue);
		height: 2px;
	}

	.HeroCard___minimal & {
		position: relative;
		justify-content: center;
		min-height: 22rem;
		background-color: var(--color-primary-light);
		max-width: 100%;

		@include mq('md') {
			padding-top: var(--spacing--lg);
			padding-bottom: var(--spacing--2xl);
		}

		@include mq('md', max) {
			padding-top: var(--spacing--md);
			padding-bottom: var(--spacing--md);
		}
	}

	.HeroCard___simple & {
		padding: 0;
		max-width: 100%;
	}

	.HeroCard___blocked & {
		position: relative;
		background-color: var(--color-primary-light);
		max-width: 100%;

		@include mq('md') {
			padding-top: var(--spacing--lg);
			padding-bottom: var(--spacing--2xl);
		}

		@include mq('md', max) {
			padding-top: var(--spacing--md);
			padding-bottom: var(--spacing--md);
		}

		:global(.Sydbank-theme) & {
			background-color: transparent;
		}
	}

	.HeroCard___offset & {
		position: relative;
		padding-top: var(--spacing--base);
		background-color: var(--color-primary-light);
		max-width: var(--content-size--default);

		@include mq('md') {
			transform: translateY($offset-md);
			margin-bottom: var(--spacing--6xl);
			padding-bottom: var(--spacing--2xl);

			:global(.Erhvervssikring-theme) & {
				padding: var(--spacing--3xl) var(--spacing--5-5xl);
				transform: none;
				margin-top: $offset-md;
			}
		}

		@include mq('md', max) {
			transform: translateY($offset-xs);
			margin: 0 var(--spacing--md) var(--spacing--4xl);
			padding-bottom: var(--spacing--md);

			:global(.Erhvervssikring-theme) & {
				transform: none;
				margin-top: $offset-xs;
			}
		}
	}
}

.HeroCard_heading {
	margin: var(--spacing--base) 0;

	:global(.Privatsikring-theme) & {
		@include mq('md', max) {
			margin-bottom: var(--spacing--sm);
		}
	}

	:global(.Codan-theme) & {
		color: var(--color-dark-blue);
	}

	.HeroCard___minimal & {
		@include heading--lg;
		margin: var(--spacing--2xs) 0;
	}

	.HeroCard___simple & {
		width: 100%;
		margin-bottom: 0;
		@include heading--lg;

		@include mq('md', max) {
			padding: var(--spacing--base);
		}

		@include mq('md') {
			padding: calc(var(--spacing--4xl) / 2) var(--spacing--2-5xl);
		}
	}

	.HeroCard___blocked & {
		width: 100%;
		max-width: var(--content-size--md);
	}

	.HeroCard___offset & {
		margin: var(--spacing--sm) 0;
		width: 100%;
		max-width: var(--content-size--md);
	}
}

.HeroCard_kicker {
	position: relative;
	top: unset;
	left: unset;
	text-transform: uppercase;
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--semibold);
	color: inherit;

	:global(.Erhvervssikring-theme) & {
		@include esk-trumpet;
	}

	:global(.Codan-theme) & {
		color: var(--color-dark-blue);
		font-weight: var(--font-weight--medium);
	}

	@include mq('md') {
		margin-bottom: var(--spacing--sm);

		:global(.Erhvervssikring-theme) & {
			margin: var(--spacing--sm) 0;
		}
	}

	@include mq('md', max) {
		margin-bottom: var(--spacing--xs);

		:global(.Erhvervssikring-theme) & {
			margin: var(--spacing--sm) 0;
		}
	}

	.HeroCard___minimal & {
		display: none;
	}

	.HeroCard___blocked &,
	.HeroCard___offset & {
		width: 100%;
		max-width: var(--content-size--md);
	}
}

.HeroCard_teaser {
	max-width: var(--content-size--md);
	margin: var(--spacing--base) auto var(--spacing--md);

	:global(.Privatsikring-theme) & {
		margin-top: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}

	.HeroCard___minimal & {
		margin: 0 auto;
	}

	.HeroCard___blocked &,
	.HeroCard___offset & {
		width: 100%;
		max-width: var(--content-size--md);
	}
}

:global(.Sydbank-theme) {
	.HeroCard {
		color: var(--color-primary);
	}
}
