$iconWrapperSize: 5.6rem;

.Infobox {
	position: relative;
	margin-top: calc($iconWrapperSize / 2);
	height: calc(100% - calc($iconWrapperSize / 2));
	transition: all var(--trans-time--shorter) var(--ease--dynamic);
}

.Infobox_iconWrapper {
	width: $iconWrapperSize;
	height: $iconWrapperSize;
	border-radius: var(--corner-circle);
	position: absolute;
	top: 0;
	left: var(--spacing--lg);
	transform: translateY(-50%);
}

.Infobox_icon {
	width: 2.5rem;
	height: 1.7rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.Infobox_content {
	padding: var(--spacing--3xl) var(--spacing--lg) var(--spacing--2-5xl);
}

.Infobox_text {
	margin-top: var(--spacing--md);

	p:last-child {
		margin-bottom: 0;
	}
}

:global(.Erhvervssikring-theme) {
	.Infobox {
		background-color: var(--color-functional-dark-blue);

		&:hover {
			background-color: var(--color-white);
			box-shadow: 0px 4px 30px 2px rgba(0, 0, 0, 0.1);

			.Infobox_content {
				color: var(--color-functional-dark-blue);
			}

			.Infobox_text {
				color: var(--color-dark);
			}
		}
	}

	.Infobox_iconWrapper {
		background-color: var(--color-business-green);
	}

	.Infobox_icon {
		color: var(--color-white);
	}

	.Infobox_content {
		color: var(--color-white);
	}
}
