@import 'styles/2-tools/tools.media-query.scss';

.FooterCard {
	display: flex;
	color: currentColor;

	@include mq('md', max) {
		flex-direction: column;
	}
	@include mq('xs', max) {
		padding: 0;
	}
}

.FooterCard_text {
	width: 75%;
	min-width: 75%;
	@include mq('lg', max) {
		width: 100%;
	}
}
.FooterCard_text p {
	margin-bottom: var(--spacing--xs);
	font-size: var(--font-size--sm);
}

.FooterCard_text a {
	@extend .FooterCard_link;
}

.FooterCard_links {
	display: flex;
	list-style: none;
	flex-direction: column;

	@include mq('lg', max) {
		width: 100%;
	}

	@include mq('md', max) {
		padding-top: var(--spacing--3xl);
	}
}

.FooterCard_linkItem {
	text-align: left;
	margin: 0 0 var(--spacing--2xs) 0;
	font-size: var(--font-size--sm);
	@include mq('md', max) {
		margin: 0 0 var(--spacing--sm) 0;
	}
}

.FooterCard_linkItem_external {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNy4xMyA3LjEzIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ViZjZmYzt9PC9zdHlsZT48L2RlZnM+PHBvbHlnb24gY2xhc3M9ImNscy0xIiBwb2ludHM9IjYuNzUgNy4wNSAuMDIgNy4wNSAuMDIgLjMyIDIuNyAuMzIgMi43IC44NyAuNTcgLjg3IC41NyA2LjUgNi4yIDYuNSA2LjIgNC40MyA2Ljc1IDQuNDMgNi43NSA3LjA1Ii8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSIyLjkiIHk9IjEuNzUiIHdpZHRoPSI0LjY0IiBoZWlnaHQ9Ii41NSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLjE0IDQuMzYpIHJvdGF0ZSgtNDUuOSkiLz48cmVjdCBjbGFzcz0iY2xzLTEiIHg9IjMuNzQiIHk9Ii4wOCIgd2lkdGg9IjMuMSIgaGVpZ2h0PSIuNTUiLz48cmVjdCBjbGFzcz0iY2xzLTEiIHg9IjYuNTYiIHk9Ii4wOCIgd2lkdGg9Ii41NSIgaGVpZ2h0PSIzLjEiLz48L3N2Zz4=);
		width: var(--spacing--sm);
		height: var(--spacing--sm);
		left: var(--spacing--2xs);
		bottom: calc(var(--spacing--2xs) / 2);
	}
}

.FooterCard_link {
	color: currentColor;
	@include mq('lg', max) {
		position: relative;
		text-decoration: none;
		&:after {
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			height: 1px;
			width: 100%;
			background-color: currentColor;
		}
	}
}

:global(.Privatsikring-theme) {
	.FooterCard_link {
		&:hover,
		&:focus {
			text-decoration: none;
			color: var(--color-tertiary);
		}
	}
}

:global(.Codan-theme) {
	.FooterCard_text strong {
		font-weight: var(--font-weight--semibold);
	}

	.FooterCard_link {
		color: var(--color-primary);
	}
}

:global(.Sydbank-theme) {
	.FooterCard {
		font-family: Ab-WorkSans, sans-serif;
		font-weight: var(--font-weight--medium);
		font-size: var(--font-size--md);
		-webkit-font-smoothing: antialiased;
		a {
			text-decoration: none;
			color: var(--color-broken-white);
			font-weight: var(--font-weight--normal);
			&:hover {
				text-decoration: underline;
			}
		}
		&_link {
			color: currentColor;
			@include mq('lg', max) {
				&:after {
					display: none;
				}
			}
		}
		&_links {
			flex-wrap: wrap;
			padding-top: 0;
			// @include mq("md", max) {
			// }
		}
		&_linkItem {
			margin: 0 0 4px 0;
			padding-top: 16px;
			&:last-child {
				margin-bottom: 32px;
			}
		}

		&.FooterCard_secondary {
			.FooterCard_links {
				flex-direction: row;
				flex-grow: 1;
				.FooterCard_linkItem {
					margin: 0 48px 0 0;
					a {
						color: var(--color-white);
					}
				}
				@include mq('md', max) {
					padding-top: 0;
				}
			}
		}
	}
	.FooterCard_text p {
		font-family: Ab-WorkSans, sans-serif;
		font-weight: var(--font-weight--medium);
		font-size: var(--font-size--md);
	}
}
