@import 'styles/2-tools/tools.media-query.scss';

.ColumnsLayout {
	margin: var(--spacing--lg) auto;
}

.ColumnsLayout_wrapper {
	display: flex;
	flex-direction: column;

	@include mq('lg', max) {
		gap: var(--spacing--base);
		padding: var(--spacing--3xl) var(--spacing--md);
		.ColumnsLayout___switchOrderForMobile & {
			flex-direction: column-reverse;
		}
	}
	@include mq('lg') {
		gap: var(--spacing--2xl);
		flex-direction: row;
		padding: var(--spacing--4xl);
	}
}

.ColumnsLayout_column {
	width: 100%;
	display: flex;
	flex-direction: column;

	&___verticallyAlign {
		justify-content: center;
	}
}
