.Image {
	display: block;
	width: 100%;

	&.isFallback {
		background-position: center center;
		background-size: cover;
		top: 0;
		left: 0;
	}
	&.Image___center {
		display: flex;
		justify-content: center;
	}
	&___cover {
		height: 100%;
	}
}

.Image_asset {
	display: block;
	fill: currentColor;
	width: auto;
	max-height: 100%;
	max-width: 100%;
	height: 100%;

	.Image___fullWidth & {
		width: 100%; // if no new svg's then width: 340px;
		height: auto;
	}

	.Image___cover & {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.Image___circular & {
		border-radius: var(--corner-circle);
	}
}
