@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/2-tools/tools.keyboard-focus.scss';

.ProfileLinks {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: var(--content-size--xs);
	margin-left: auto;
	margin-right: auto;

	@include mq('lg') {
		display: none;
	}

	&___desktop {
		display: none;

		@include mq('lg') {
			display: flex;
			align-items: center;
			position: relative;
			white-space: nowrap;
			cursor: pointer;
			margin-right: var(--spacing--md);
		}
	}
}

.ProfileLinks_button {
	background: none;
	border: none;
	padding: 0;
}

.ProfileLinks_link {
	color: currentColor;
	text-decoration: none;
}

.ProfileLinks_icon {
	display: flex;
	background-color: var(--color-primary);
	border-radius: var(--spacing--xl);
	height: var(--spacing--xl);
	width: var(--spacing--xl);
	justify-content: center;
	margin-right: var(--spacing--2xs);
}

.ProfileLinks_iconImage {
	filter: brightness(0) invert(1);
}

.ProfileLinks_title {
	position: relative;
	display: flex;
	align-items: center;
	font-size: var(--font-size--sm);
	border: var(--border--md) solid transparent;
}

.ProfileLinks_list {
	display: block;
	background: var(--color-primary-contrast);
	list-style-type: none;

	@include mq('lg') {
		z-index: calc(var(--z-index--header) + 2);
		width: 25rem;
		position: absolute;
		right: -2rem;
		top: var(--spacing--3xl);
	}
}

.ProfileLinks_listItem {
	position: relative;
	text-decoration: none;
	white-space: nowrap;
	display: inline-block;
	cursor: pointer;
	font-weight: var(--font-weight--light);
	font-size: var(--font-size--md);
	line-height: var(--line-height--5xl);
	color: var(--color-dark);
	width: 100%;
	height: 100%;
	text-align: left;

	&:hover,
	&:focus {
		@include mq('lg') {
			background-color: var(--color-primary-dark);
			&:before {
				content: ' ';
				background-image: url('../../../../public/icons/arrow.png');
				background-repeat: no-repeat;
				background-position: center center;
				display: inline-block;
				position: absolute;
				top: 2.2rem;
				left: 1.4rem;
				width: 1rem;
				height: 1.3rem;
			}
		}
	}

	@include mq('lg', max) {
		padding-left: var(--spacing--base);
		line-height: 3.8rem;
	}

	@include mq('lg') {
		border-bottom: var(--border--sm) solid var(--color-primary-light);
		padding: 0 var(--spacing--xl);
	}

	&___active {
		font-weight: var(--font-weight--bold);
	}
}

.ProfileLinks_mastercard {
	display: none;
}

:global(.Dark-theme--masterCardPage) {
	.ProfileLinks_button,
	.ProfileLinks_link,
	.ProfileLinks_list {
		display: none;
	}

	.ProfileLinks___mastercard {
		display: block;
	}
}

:global(.Erhvervssikring-theme) {
	.ProfileLinks_list {
		background: transparent;
	}
}

:global(.Codan-theme) {
	.ProfileLinks {
		@include mq('lg') {
			margin-right: var(--spacing--3xl);
		}

		&_list {
			background: var(--color-primary);
		}
	}

	.ProfileLinks_list {
		background-color: var(--color-primary);

		@include mq('lg', max) {
			background-color: var(--color-primary-contrast);
		}
	}

	.ProfileLinks_listItem {
		color: white;

		&:hover,
		&:focus {
			@include mq('lg') {
				background: var(--color-primary-contrast);

				&:before {
					content: ' ';
					background-image: url('../../../../public/icons/arrow-white.png');
				}
			}
		}
	}
}
