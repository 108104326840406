.Dark-theme {
	--color-background-default: #515151;
	background-color: var(--color-background-default);
	&.Sydbank-theme {
		--color-background-default: #272a30;
		--color-emperor: #080a0d; // #151619;
		--color-dove-gray: #151619; // #212326;
		--color-nav_open: #828282;
		background-color: var(--color-background-default);

		// Theme colors
		--color-background: var(--color-emperor);
		--color-background-lighter: var(--color-dove-gray);
		--color-background-lightest: var(--color-gray);
		--color-background-nav_open: var(--color-nav_open);

		--color-primary: var(--color-sky-blue);
	}
	// background-color: #272A30;

	// --color-emperor: #515151;
	// --color-dove-gray: #626262;
	// --color-gray: #828282;
	// --color-zambezi: #6f5860;

	// // Theme colors
	// --color-background: var(--color-emperor);
	// --color-background-lighter: var(--color-dove-gray);
	// --color-background-lightest: var(--color-gray);

	// // Utilities colors
	// --color-error-contrast: var(--color-zambezi);
}
