/* ===========================================
	utilities.container
	========================================= */
/**
	*  Utilities – utility- and helper classes
	*  with the ability to override anything
*/
@import 'src/styles/2-tools/tools.media-query.scss';

.u-container {
	max-width: 102.4rem;
	margin-left: auto;
	margin-right: auto;

	@include mq('md', max) {
		padding: 0 var(--spacing--md);
	}

	@include mq('md') {
		padding: 0 var(--spacing--lg);
	}
}

.u-container--narrow {
	max-width: calc(var(--content-size--default) + var(--spacing--md) * 2);
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--spacing--md);
}

.u-container--slim {
	max-width: calc(var(--content-size--sm) + var(--spacing--md) * 2);
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--spacing--base);
}

.u-container--skinny {
	max-width: calc(var(--content-size--xs) + var(--spacing--md) * 2);
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--spacing--base);
}

.u-container--large {
	max-width: calc(var(--content-size--large) + var(--spacing--md) * 2);
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--spacing--md);
}

.u-container--xl {
	max-width: calc(var(--content-size--xl) + var(--spacing--md) * 2);
	width: 100%;
	padding: 0 var(--spacing--md);
	margin-left: auto;
	margin-right: auto;
}

.u-container--fullWidth {
	width: 100%;
}

.u-container--fullWidth-mobile {
	max-width: 102.4rem;
	margin-left: auto;
	margin-right: auto;

	@include mq('md', max) {
		width: 100%;
		max-width: none;
	}

	@include mq('md') {
		padding: 0 var(--spacing--lg);
	}
}
