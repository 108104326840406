.Select {
	position: relative;
	padding: 0 var(--spacing--3xl) 0 var(--spacing--lg);
	border-radius: var(--corner-size--lg2);
}

.Select_select {
	appearance: none;
	border: none;
	height: 100%;
	width: 100%;
	background-color: transparent;
}

:global(.Erhvervssikring-theme) {
	.Select {
		border: 0.1rem solid var(--color-business-medium-gray);

		&:before {
			content: '';
			display: block;
			background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M13 1 7 7 1 1' stroke='%232E2B2B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E");
			background-repeat: no-repeat;
			position: absolute;
			right: var(--spacing--lg);
			top: 50%;
			transform: translateY(-50%);
			width: 1.2rem;
			height: 0.6rem;
		}
	}

	.Select_select {
		font-size: var(--font-size--lg);
	}
}
