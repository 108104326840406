@import 'styles/2-tools/tools.media-query';

.MasterCardSummary {
	@include mq('md') {
		margin-bottom: var(--spacing--9xl);
	}
	@include mq('md', max) {
		margin-bottom: var(--spacing--7xl);
	}
}

.MasterCardSummary_priceDetailsList {
	> dl {
		div:last-child {
			margin-top: var(--spacing--4xl);
		}
	}
}

.MasterCardSummary_explainerText {
	@include mq('md') {
		margin-top: calc(var(--spacing--sm) * -1);
		margin-left: 3.6rem;
	}
	@include mq('md', max) {
		margin-top: calc(var(--spacing--xs) * -1);
		margin-left: 3.2rem;
	}
}

.MasterCardSummary_cta {
	@include mq('md') {
		padding-top: var(--spacing--8xl);
	}
	@include mq('md', max) {
		padding-top: var(--spacing--5-5xl);
	}
}

:global(.Dark-theme) {
	.MasterCardSummary {
		color: var(--color-white);
	}
	.MasterCardSummary_topList {
		margin-top: 0;
	}
	.MasterCardSummary_attentioncard {
		max-width: var(--content-size--default);
		margin: -0.1rem auto;
		@include mq('lg') {
			padding: var(--spacing--2xl) var(--spacing--4xl);
		}
		@include mq('lg', max) {
			padding: var(--spacing--2xl) var(--spacing--md);
		}
	}
}

:global(.Sydbank-theme) {
	.MasterCardSummary_topList div,
	.MasterCardSummary_middleList div,
	.MasterCardSummary_priceDetailsList div:not(:last-child) {
		background-position: 0 calc(100% - 2.5rem);

		@include mq('md', max) {
			background: none;
			gap: var(--spacing--2xl);
		}

		dt {
			@include mq('md', max) {
				font-size: var(--font-size--xs);
			}

			@include mq('md') {
				font-size: var(--font-size--sm);
			}
		}

		dd {
			line-height: var(--line-height--2xl);

			@include mq('md', max) {
				font-size: var(--font-size--xs);
			}

			@include mq('md') {
				font-size: var(--font-size--sm);
			}

			> span {
				@include mq('md', max) {
					font-size: var(--font-size--xs);
				}

				@include mq('md') {
					font-size: var(--font-size--sm);
				}
			}
		}
	}

	.MasterCardSummary_attentioncard {
		max-width: var(--content-size--default);
		margin: -0.1rem auto;
		@include mq('lg', max) {
			padding: 0 var(--spacing--md);
		}
		@include mq('lg') {
			padding: 0;
		}
	}

	.MasterCardSummary_conditions {
		@include mq('md') {
			margin: var(--spacing--4xl) auto var(--spacing--3xl);
		}
		@include mq('md', max) {
			margin: var(--spacing--5-5xl) auto;
		}
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.MasterCardSummary_attentioncard {
		max-width: var(--content-size--default);
		margin: -4rem auto 4rem;
		@include mq('lg') {
			padding: 0 var(--spacing--4xl);
		}
		@include mq('lg', max) {
			padding: 0 var(--spacing--md);
		}
	}

	.MasterCardSummary_ctaWrapper {
		background: transparent;
	}
}
