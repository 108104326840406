/* ------------------------------------ *\
		settings.corners
\* ------------------------------------ */

:root {
	--corner-size--base: 0.5rem;

	--corner-size--xs: calc(var(--corner-size--base) * 0.6);
	--corner-size--sm: calc(var(--corner-size--base) * 2);
	--corner-size--md: calc(var(--corner-size--base) * 3);
	--corner-size--lg: calc(var(--corner-size--base) * 4);
	--corner-size--lg2: calc(var(--corner-size--base) * 8);

	--corner-size--xl: calc(var(--corner-size--base) * 10);

	--corner-circle: 50%;
}
