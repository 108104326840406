@mixin buttonSydbankTheme {
	&___primary {
		color: #fff;
		background-color: var(--color-sky-blue);
		opacity: 1;
		font-weight: var(--font-weight--bold);

		&:hover {
			background-color: var(--color-hover-blue);
		}

		&___inactive,
		&:disabled {
			opacity: 1;
			pointer-events: none;
			background-color: var(--color-ab-gray);

			&:hover,
			&:focus {
				transition: none;
			}
		}
	}
}
