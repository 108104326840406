@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/2-tools/tools.typography-mixins.scss';
@import 'styles/50-themes/theme-sydbank/2-tools/tools.typography-mixins.scss';

.MasterCardHeader {
	width: 100%;
	margin-bottom: var(--spacing--2xl);
	@include mq('md') {
		margin-top: var(--spacing--8xl);
	}
	@include mq('md', max) {
		margin-top: var(--spacing--2xl);
	}
}

.MasterCardHeader_wrapper {
	display: grid;
	align-items: flex-end;
	@include mq('md') {
		// grid-template-columns: 1fr;
		column-gap: var(--spacing--9xl);
	}
	@include mq('md', max) {
		grid-template-columns: 1fr 7rem;
	}
	.MasterCardHeader___withDescription & {
		@include mq('md') {
			align-items: flex-start;
		}
	}
}

.MasterCardHeader_heading {
	@include heading--sm;
	max-width: var(--text-width);

	:global(.Sydbank-theme) & {
		@include sydbank-heading--sm;
	}
}

.MasterCardHeader_description {
	display: grid;
	margin-top: var(--spacing--base);
	line-height: var(--line-height--2xl);
	max-width: var(--text-width);

	&_hidden {
		visibility: hidden;
		@include mq('md', max) {
			display: none;
		}
	}

	@include mq('md', max) {
		grid-column: 1/-1;
	}
}

.MasterCardHeader_image {
	width: 100%;
	@include mq('md') {
		grid-column: 2;
		.MasterCardHeader___withDescription & {
			grid-row: 1 / 3;
		}
	}
}

:global(.Dark-theme) {
	.MasterCardHeader {
		position: relative;
		color: var(--color-white);
		margin-bottom: 0;
		@include mq('lg', max) {
			margin-top: 0;
		}
		margin-bottom: -0.1rem;
	}
	.MasterCardHeader_wrapper {
		padding-bottom: var(--spacing--2xl);
		padding-top: var(--spacing--5xl);
		@include mq('lg') {
			padding-top: 0;
			min-height: 24rem;
		}
	}
	.MasterCardHeader_image {
		@include mq('lg') {
			position: absolute;
			width: 30rem;
			left: 50%;
			transform: translateX(-50%);
			top: -5rem;
		}
	}
	.MasterCardHeader_heading {
		@include mq('lg') {
			margin-top: 19rem;
		}
	}
	.MasterCardHeader_description {
		grid-row: 2;
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.MasterCardHeader_wrapper {
		padding-bottom: 0;
	}
}
