@import 'styles/2-tools/tools.media-query';
$text-height: 4rem;
.DescriptionListItem {
	display: flex;
	justify-content: space-between;
	font-size: var(--font-size--lg);
	background-position: 0 calc(100% - $text-height / 2);
	background-repeat: repeat-x;
	background-size: 10px 1px;
	background-image: linear-gradient(to right, var(--color-border--gray) 10%, rgba(255, 255, 255, 0) 0%);
	&___large,
	&___highlight {
		background-image: linear-gradient(to right, var(--color-border--dark) 10%, rgba(255, 255, 255, 0) 0%);
	}
}

.DescriptionListItem_text {
	background-color: var(--color-white);
	min-height: $text-height;
	.DescriptionListItem___highlight & {
		font-weight: var(--font-weight--semibold);
	}
	&___term {
		padding-right: var(--spacing--2xs);
		@include mq('md', max) {
			font-size: var(--font-size--md);
		}
	}
	&___description {
		font-weight: var(--font-weight--medium);
		padding-left: var(--spacing--2xs);
		white-space: nowrap;
		.DescriptionListItem___large & {
			font-weight: var(--font-weight--normal);
		}
		.DescriptionListItem___large &,
		.DescriptionListItem___highlight & {
			line-height: 1;
			font-size: var(--font-size--4xl);
		}
	}
}

.DescriptionListItem_decorator {
	margin-left: var(--spacing--2xs);
	font-size: var(--font-size--md);
}

:global(.Dark-theme) {
	.DescriptionListItem {
		background-image: linear-gradient(to right, var(--color-white) 10%, rgba(255, 255, 255, 0) 0%);
	}
	.DescriptionListItem_text {
		background-color: var(--color-background-lighter);
		color: var(--color-white);
	}
}

:global(.Sydbank-theme) {
	.DescriptionListItem:not(:last-of-type) {
		.DescriptionListItem_text {
			@include mq('md', max) {
				display: flex;
				align-items: flex-end;
				min-height: 2rem;
			}
		}
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.DescriptionListItem {
		background-image: linear-gradient(to right, var(--color-border--gray) 10%, rgba(255, 255, 255, 0) 0%);
	}

	.DescriptionListItem:not(:last-of-type) {
		.DescriptionListItem_text {
			@include mq('md', max) {
				display: flex;
				align-items: flex-end;
				min-height: 2rem;
			}
		}
	}
}
