@import 'styles/2-tools/tools.media-query.scss';

.ArticleText {
	@include mq('md') {
		margin-top: var(--spacing--6xl);
		margin-bottom: var(--spacing--8xl);
	}

	@include mq('md', max) {
		margin-top: var(--spacing--4xl);
		margin-bottom: var(--spacing--6xl);
	}
}

:global(.Erhvervssikring-theme) {
	.ArticleText {
		@include mq('md') {
			margin-top: var(--spacing--3xl);
		}

		@include mq('md', max) {
			margin-top: var(--spacing--3xl);
		}
	}
}
