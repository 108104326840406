.MasterCardCta {
	display: flex;
	justify-content: center;
}
.MasterCardCta_button {
	margin: 0 var(--spacing--xs);
}

:global(.Dark-theme) {
	.MasterCardCta {
		padding-bottom: var(--spacing--9xl);
	}
}
