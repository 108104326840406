@import 'styles/2-tools/tools.typography-mixins.scss';
@import 'styles/2-tools/tools.display-mixins.scss';

.FormLabel {
	@include paragraph--sm;
	@include mq('md', max) {
		@include paragraph--xs;
	}
	display: flex;
	width: 100%;
	padding-bottom: var(--spacing--2xs);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--50);
		cursor: auto;
	}

	&___bold {
		font-weight: var(--font-weight--bold);
	}
	&___normal {
		font-weight: var(--font-weight--normal);
	}
}

.FormLabel___required {
	color: var(--color-error);
	margin-left: var(--spacing--2xs);
}

.FormLabel___hidden {
	display: none;
}

:global(.Sydbank-theme) {
	.FormLabel___required {
		display: none;
	}
}
