@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/2-tools/tools.display-mixins.scss';

.LinkSelectorModule {
	max-width: 70rem;
	margin: var(--spacing--4xl) auto;
}

.LinkSelectorModule_wrapper {
	@include mq('md') {
		display: flex;
		gap: var(--spacing--xs);
	}
}

.LinkSelectorModule_heading {
	margin-bottom: var(--spacing--sm);

	@include mq('md') {
		margin-bottom: var(--spacing--md);
	}
}

.LinkSelectorModule_text {
	margin-bottom: var(--spacing--2xl);
}

.LinkSelectorModule_label {
	@include visually-hidden;
}

.LinkSelectorModule_select {
	height: 6rem;
	margin-bottom: var(--spacing--md);

	@include mq('md') {
		flex: 1 0 auto;
	}
}

.LinkSelectorModule_button {
	width: 100%;
	height: 6rem;

	@include mq('md') {
		width: auto;
		flex: 0 1 auto;
	}
}
