// TODO: SHOULD BE REMOVED AND REPLACED BY STYLING IN MODULE

.u-center-absolute {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.u-text-center {
	text-align: center;
}
