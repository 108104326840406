@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/2-tools/tools.aspect-ratio.scss';

$mobileContentContainerPadding: var(--spacing--lg);
$desktopImageOverlap: var(--spacing--3xl);

.ProductSection {
	position: relative;
	@include mq('lg') {
		margin-bottom: calc($desktopImageOverlap + var(--spacing--8xl));
		&___padded {
			margin-bottom: 0;
			padding-bottom: $desktopImageOverlap;
		}
	}
}

.ProductSection_imageBackground {
	background-color: var(--color-white);
	@include mq('lg', max) {
		position: absolute;
		top: 0;
		width: 100%;
		padding-top: calc(90% - $mobileContentContainerPadding - $mobileContentContainerPadding);
	}
}

.ProductSection_wrapper {
	background-color: var(--color-tertiary);
	&___styleLight {
		background-color: var(--color-white);
	}
}
.ProductSection_contentContainer {
	padding: $mobileContentContainerPadding 0;

	display: flex;
	flex-direction: column;

	@include mq('lg') {
		padding: 0;

		flex-direction: row;

		gap: var(--spacing--4xl);
	}

	.ProductSection___flipped & {
		@include mq('lg') {
			flex-direction: row-reverse;
		}
	}
}

.ProductSection_imageContainer {
	margin-bottom: var(--spacing--2xl);

	@include mq('lg') {
		margin-bottom: 0;
		width: 65%;
	}
}

.ProductSection_imageRatioContainer {
	@include aspect-ratio(100, 90);
	overflow: unset;
}

.ProductSection_image {
	@include mq('lg') {
		top: unset;
		bottom: calc(-1 * $desktopImageOverlap);
	}
}

.ProductSection_richContent {
	position: relative;
	display: inline-flex;
	flex-direction: column;
	color: var(--color-white);
	.ProductSection_wrapper___styleLight & {
		color: var(--color-dark);
	}

	@include mq('lg') {
		bottom: calc(-1 * $desktopImageOverlap);
		margin: auto 0;
		width: 35%;
	}
}
