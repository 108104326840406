@import 'styles/2-tools/tools.action.scss';

.Link {
	@include action;

	&___sm {
		font-size: var(--font-size--sm);
	}

	&___dash {
		color: var(--color-dark);
		text-decoration: none;
		font-weight: var(--font-weight--medium);
		display: flex;
		align-items: center;
		text-transform: uppercase;

		&::before {
			height: 0.1rem;
			width: 2.6rem;
			border: solid 0.1rem var(--color-primary);
			margin-right: var(--spacing--xs);
			content: ' ';
		}
	}

	:global(.Codan-theme) & {
		&___dash {
			&::before {
				border: solid 0.1rem var(--color-tertiary);
			}
		}
	}
}
