@import 'src/styles/2-tools/tools.media-query.scss';

@mixin heading--lg {
	font-family: var(--font-primary);
	line-height: var(--line-height--lg);
	@include mq('lg', max) {
		font-weight: var(--font-weight--semibold);
		font-size: var(--font-size--3xl);
	}
	@include mq('lg') {
		font-weight: var(--font-weight--medium);
		font-size: var(--font-size--5xl);
	}
}
