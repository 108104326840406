@import 'styles/2-tools/tools.media-query.scss';

.MasterCardList {
	margin: var(--spacing--6xl) auto var(--spacing--8xl);
	max-width: var(--content-size--default);
	@include mq('sm', max) {
		margin: var(--spacing--4xl) auto var(--spacing--6xl);
	}

	@include mq('lg', max) {
		padding: 0 var(--spacing--md);
	}

	&___includesHeading {
		margin-top: var(--spacing--8xl);

		@include mq('sm', max) {
			margin-top: var(--spacing--6xl);
		}
	}
}

.MasterCardList_heading {
	margin-bottom: var(--spacing--3xl);
	color: var(--color-dark);
	text-align: center;
	min-height: 9.5rem;
}
