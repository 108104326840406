.ScrollTransition {
	opacity: 0;
	transform: translateY(10vh);
	visibility: hidden;
	transition: opacity 1200ms ease-out, transform 1200ms ease-out, visibility 1200ms ease-out;
	will-change: opacity, transform, visibility;

	&___visible {
		opacity: 1;
		transform: none;
		visibility: visible;
	}
}
