/* ------------------------------------ *\
		tools.action
\* ------------------------------------ */

@import 'src/styles/2-tools/tools.behavior.scss';
@import 'src/styles/2-tools/tools.borders.scss';
@import 'src/styles/2-tools/tools.typography-mixins.scss';
@import 'src/styles/50-themes/theme-codan/tools.action.scss';
// Action
@mixin action {
	padding: 0;
	background: transparent;
	border-color: transparent;
	font-weight: var(--font-weight--semibold);
	cursor: pointer;
}

// Button
@mixin button {
	@include action;

	display: inline-block;
	font-size: var(--font-size--xl);
	padding: var(--spacing--sm) var(--spacing--lg);
	text-decoration: none;
	line-height: var(--line-height--lg);
	border: var(--border--md) solid transparent;
	border-radius: var(--corner-size--xl);
	text-align: center;
	min-width: 10em;

	&___small {
		font-size: var(--font-size--xs);
		padding: var(--spacing--xs) var(--spacing--base);
	}
	&___medium {
		font-size: var(--font-size--sm);
		padding: var(--spacing--xs) var(--spacing--base);
		min-height: 5rem;
	}

	@include transition {
		transition: all var(--trans-time--shorter);
	}

	&___primary {
		color: var(--color-black);
		background: var(--color-primary);
		text-transform: uppercase;
		font-size: var(--font-size--sm);
		font-weight: var(--font-weight--medium);
		&:hover,
		&:focus {
			color: var(--color-black);
			background-color: var(--color-primary-dark);
		}
	}

	&___secondary {
		color: var(--color-black);
		@include border-default;
		text-transform: uppercase;
		font-size: var(--font-size--sm);
		font-weight: var(--font-weight--medium);

		:global(.Erhvervssikring-theme) & {
			@include border-primary;
		}

		&:hover,
		&:focus {
			background-color: var(--color-background-contrast-dark);
		}
	}
	&___link {
		@include paragraph;
		padding: var(--spacing--xs);
		color: var(--color-tertiary);
		text-decoration: underline;
	}

	&___inactive,
	&:disabled {
		opacity: 0.3;
		pointer-events: none;

		&:hover,
		&:focus {
			transition: none;
		}
	}
}

// Input
@mixin input {
	@include paragraph;
	outline: none;
	padding: var(--spacing--sm) var(--spacing--base);

	&.hasError {
		border-color: var(--color-error);
		color: var(--color-error);
	}

	&::placeholder {
		color: currentColor;
	}

	// Used for text, email, password, number etc.
	&___string {
		width: 100%;
		border: var(--border--sm) solid transparent;
		background: var(--color-gradient--5);
		-webkit-appearance: none;

		&.hasError {
			border-color: var(--color-error);
		}
	}

	&___string:focus,
	&___active {
		// outline-color: transparent;
		border-color: currentColor;
	}

	// Used for checkbox and radio
	&___choice {
		height: 1.5em;
		width: 1.5em;
	}

	// Used for textarea
	&___textarea {
		width: 100%;
		border: var(--border--sm) solid currentColor;
		-webkit-appearance: none;

		&.hasError {
			border-color: var(--color-error);
		}
	}
}
