@import 'styles/2-tools/tools.borders.scss';
@import 'styles/2-tools/tools.keyboard-focus.scss';
@import 'styles/2-tools/tools.media-query.scss';
@import 'styles/2-tools/tools.shadows.scss';
@import 'styles/50-themes/theme-erhvervssikring/2-tools/_tools.typography-mixins.scss';

.ProductOverviewItem {
	display: flex;
	flex-flow: column;
	align-items: center;
	height: 100%;
	padding: var(--spacing--sm) var(--spacing--md);
	text-align: center;
	cursor: pointer;
	@include shadow-primary;
	text-decoration: none;
	color: var(--color-dark);
	transition: transform 200ms;

	&:hover {
		transform: translateY(-1rem);

		:global(.Erhvervssikring-theme) & {
			transform: unset;
		}
	}

	&:focus {
		@include keyboard-focus {
			border: 0.1rem solid var(--color-dark);
		}
	}

	:global(.Codan-theme) & {
		color: var(--color-primary);
	}

	:global(.Erhvervssikring-theme) & {
		padding: var(--spacing--md); // 2.5rem. Design is 24px.
		background-color: var(--color-white);
		align-items: flex-start;
		text-align: start;
		gap: var(--spacing--sm); // 1.5rem. Design is 16px.
		box-shadow: unset;

		@include mq('lg') {
			padding: var(--spacing--lg); // 3rem. Design is 32px.
		}

		&:hover,
		&:focus {
			@include shadow-primary;
		}
	}

	:global(.Erhvervssikring-theme) & {
		&___grayBackground {
			background-color: var(--color-functional-light-blue);

			&:hover,
			&:focus {
				background-color: var(--color-white);
			}
		}
	}
}

.ProductOverviewItem_iconTitleTagContainer {
	:global(.Erhvervssikring-theme) & {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: var(--spacing--sm); // 1.5rem. Design is 16px.
	}
}

.ProductOverviewItem_icon {
	height: 6rem;
	width: 6rem;
	margin: 0 auto;
	padding: var(--spacing--xs);
	border-radius: var(--spacing--xl);

	:global(.Erhvervssikring-theme) & {
		display: none;

		@include mq('lg') {
			display: unset;
			margin: 0;
			width: 5.6rem;
			height: 5.6rem;
			padding: 0;
		}
	}
}

.ProductOverviewItem_titleAndTagContainer {
	:global(.Erhvervssikring-theme) & {
		@include mq('lg') {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			gap: var(--spacing--xs);
		}
	}
}

.ProductOverviewItem_title {
	font-size: var(--font-size--md);
	font-weight: var(--font-weight--semibold);
	line-height: 1;
	margin-top: var(--spacing--xs);
	margin-bottom: var(--spacing--2xs);

	:global(.Erhvervssikring-theme) & {
		margin: 0;
		@include esk-heading--lg;
		font-weight: var(--font-weight--semibold);
		color: var(--color-functional-dark-blue);
	}

	:global(.Erhvervssikring-theme) .ProductOverviewItem:hover &,
	:global(.Erhvervssikring-theme) .ProductOverviewItem:focus & {
		color: var(--color-business-green);
	}
}

.ProductOverviewItem_tag {
	margin: 0;
	// Font styles from Figma: https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=168%3A4030
	font-family: var(--font-primary);
	font-size: var(--font-size--xs);
	font-weight: var(--font-weight--medium);
	line-height: var(--line-height-2xs);
	letter-spacing: 0.05rem;
	white-space: nowrap;

	@include mq('lg') {
		position: absolute;
		top: 0;
		right: 0;
		// Font styles from Figma: https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=168%3A4030
		font-family: var(--font-primary);
		font-size: var(--font-size--sm);
		font-weight: var(--font-weight--medium);
		line-height: var(--line-height--2xs);
		letter-spacing: 0.05rem;
		text-align: center;
		white-space: nowrap;
	}
}

.ProductOverviewItem_info {
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--light);
	line-height: var(--line-height--lg);
	margin-bottom: var(--spacing--base);
	height: 3.6rem;
	overflow: hidden;

	:global(.Erhvervssikring-theme) & {
		margin: 0;
		height: unset;
		@include esk-paragraph--sm;

		@include mq('lg') {
			@include esk-paragraph;
		}
	}
}

.ProductOverviewItem_price {
	font-size: var(--font-size--md);
	font-weight: var(--font-weight--semibold);
	margin-top: var(--spacing--base);
}

.ProductOverviewItem_priceDetails {
	font-size: var(--font-size--sm);
	margin-left: var(--spacing--2xs);
}

.ProductOverviewItem_linkButton {
	margin: var(--spacing--3xl) 0 var(--spacing--md) 0;
}
