@import 'styles/2-tools/tools.typography-mixins.scss';
@import 'styles/50-themes/theme-erhvervssikring/2-tools/tools.typography-mixins.scss';

.Download {
	margin: var(--spacing--6xl) 0;

	display: flex;
	flex-direction: column;
}

.Download_HeadingAndButtonsContainer {
	border: 0;
	border-top: 0.1rem solid var(--color-functional-gray-blue);
	border-bottom: 0.1rem solid var(--color-functional-gray-blue);

	padding: var(--spacing--2xl) 0;

	display: flex;
	flex-direction: column;
	gap: var(--spacing--lg);
}

.Download_Buttons {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--sm);
}

.Download_Button {
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.Download_Icon {
	position: relative;
	top: -0.1rem;
	margin-right: var(--spacing--xs);
	vertical-align: middle;
}
