/* ------------------------------------ *\
		settings.borders
\* ------------------------------------ */

:root {
	--border--sm: 0.1rem;
	--border--md: 0.2rem;
	--border--lg: 0.3rem;
	--border--xl: 0.4rem;
	--border--2xl: 0.5rem;
}
