.Dark-theme {
	background-color: #272a30;

	--color-emperor: #515151;
	--color-dove-gray: #626262;
	--color-gray: #828282;
	--color-zambezi: #6f5860;

	// Theme colors
	--color-background: var(--color-emperor);
	--color-background-lighter: var(--color-dove-gray);
	--color-background-lightest: var(--color-gray);

	// Utilities colors
	--color-error-contrast: var(--color-zambezi);
}
