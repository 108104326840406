@import 'src/styles/2-tools/tools.media-query';

@mixin esk-heading--2xl {
	//styleName: Mob/H1; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
	font-family: var(--font-primary);
	font-size: var(--font-size--5xl);
	font-weight: var(--font-weight--light);
	line-height: var(--line-height--lg);
	letter-spacing: var(--letter-spacing--sm);

	@include mq('lg') {
		//styleName: H1; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
		font-family: var(--font-primary);
		font-size: var(--font-size--6xl);
		font-weight: var(--font-weight--light);
		line-height: var(--line-height--2xs);
		letter-spacing: var(--letter-spacing--sm);
	}
}

@mixin esk-heading--xl {
	//styleName: Mob / H2; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
	font-family: var(--font-primary);
	font-size: var(--font-size--4xl);
	font-weight: var(--font-weight--semibold);
	line-height: var(--line-height--lg);
	letter-spacing: var(--letter-spacing--sm);

	@include mq('lg') {
		//styleName: H2; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
		font-family: var(--font-primary);
		font-size: var(--font-size--5xl);
		font-weight: var(--font-weight--semibold);
		line-height: var(--line-height--xs);
		letter-spacing: var(--letter-spacing--sm);
	}
}

@mixin esk-heading--lg {
	//styleName: Mob/H3; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
	font-family: var(--font-primary);
	font-size: var(--font-size--2xl);
	font-weight: var(--font-weight--medium);
	line-height: var(--line-height--md);
	letter-spacing: var(--letter-spacing--sm);

	@include mq('lg') {
		//styleName: H3; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
		font-family: var(--font-primary);
		font-size: var(--font-size--3xl);
		font-weight: var(--font-weight--semibold);
		line-height: var(--line-height--sm);
		letter-spacing: var(--letter-spacing--sm);
	}
}

@mixin esk-trumpet {
	//styleName: Trumpet; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
	/*
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 1px;
	text-align: left;
	*/
	font-family: var(--font-primary);
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--normal);
	line-height: var(--line-height--lg); // 18.06px. Design is 18px.
	letter-spacing: var(--letter-spacing--md);
}

@mixin esk-manchet {
	//styleName: Manchet; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
	font-family: var(--font-primary);
	font-size: var(--font-size--xl);
	font-weight: var(--font-weight--light);
	line-height: var(--line-height--xl);
	letter-spacing: var(--letter-spacing--sm);
}

@mixin esk-paragraph--xl {
	//styleName: Body large; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
	font-family: var(--font-primary);
	font-size: var(--font-size--lg);
	font-weight: var(--font-weight--light);
	line-height: var(--line-height--2xl);
	letter-spacing: var(--letter-spacing--sm);
}

@mixin esk-paragraph {
	/*
	//styleName: Body; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
	font-family: Poppins;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: left;
	*/
	font-family: var(--font-primary);
	font-size: var(--font-size--md); // 16px
	font-weight: var(--font-weight--normal);
	line-height: var(--line-height--2xl); // 24px
	letter-spacing: var(--letter-spacing--sm);
}

@mixin esk-paragraph--sm {
	/*
	//styleName: Body small; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0px;
	text-align: left;
	*/
	font-family: var(--font-primary);
	font-size: var(--font-size--sm); // 14px
	font-weight: var(--font-weight--normal);
	line-height: var(--line-height--2xl); // 21px. Design is 22px.
	letter-spacing: var(--letter-spacing--sm);
}

@mixin esk-quote--lg {
	/*
	//styleName: Quote large; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
	font-family: Poppins;
	font-size: 50px;
	font-weight: 600;
	line-height: 58px;
	letter-spacing: 0px;
	text-align: left;
	*/
	font-family: var(--font-primary);
	font-size: var(--font-size--7xl); // 46px. Design is 50px.
	font-weight: var(--font-weight--semibold);
	line-height: var(--line-height--1xs); // 69px. Design is 58px. (2xs gives 46px.)
	letter-spacing: var(--letter-spacing--sm);
}

@mixin esk-quote--sm {
	/*
	//styleName: Quote small; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
	font-family: Poppins;
	font-size: 24px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: 0px;
	text-align: left;
	*/
	font-family: var(--font-primary);
	font-size: var(--font-size--3xl); // 24px
	font-weight: var(--font-weight--medium);
	line-height: var(--line-height--lg); // 30px
	letter-spacing: var(--letter-spacing--sm);
}
