/* ------------------------------------ *\
		settings.spacing
\* ------------------------------------ */
:root {
	--spacing--base: 2rem; // 20px

	--spacing--2xs: calc(var(--spacing--base) / 4); // 5px
	--spacing--xs: calc(var(--spacing--base) / 2); // 10px
	--spacing--sm: calc(var(--spacing--base) * 0.75); // 15px
	--spacing--md: calc(var(--spacing--base) * 1.25); // 25px
	--spacing--lg: calc(var(--spacing--base) * 1.5); // 30px
	--spacing--xl: calc(var(--spacing--base) * 1.75); // 35px
	--spacing--2xl: calc(var(--spacing--base) * 2); // 40px
	--spacing--2-5xl: calc(var(--spacing--base) * 2.25); // 45px
	--spacing--3xl: calc(var(--spacing--base) * 2.5); // 50px
	--spacing--4xl: calc(var(--spacing--base) * 3); // 60px
	--spacing--5xl: calc(var(--spacing--base) * 3.5); // 70px
	--spacing--5-5xl: calc(var(--spacing--base) * 4); // 80px
	--spacing--6xl: calc(var(--spacing--base) * 4.5); // 90px
	--spacing--7xl: calc(var(--spacing--base) * 5); // 100px
	--spacing--8xl: calc(var(--spacing--base) * 6); // 120px
	--spacing--9xl: calc(var(--spacing--base) * 7); // 140px
	--spacing--10xl: calc(var(--spacing--base) * 8); // 160px
	--spacing--10-5xl: calc(var(--spacing--base) * 8.5); // 170px
	--spacing--11xl: calc(var(--spacing--base) * 9); // 180px
}
