/* ------------------------------------ *\
		settings.sizes
\* ------------------------------------ */

:root {
	// Icons
	--icon-size--base: 1rem;

	--icon-size--xs: calc(var(--icon-size--base) * 0.4);
	--icon-size--sm: calc(var(--icon-size--base) * 0.8);
	--icon-size--md: calc(var(--icon-size--base) * 2);
	--icon-size--lg: calc(var(--icon-size--base) * 3);
	--icon-size--xl: calc(var(--icon-size--base) * 4);
	--icon-size--2xl: calc(var(--icon-size--base) * 6);

	--content-size--xl: 144rem;
	--content-size--large: 120rem;
	--content-size--default: 97rem;
	--content-size--md: 90rem;
	--content-size--sm: 70rem;
	--content-size--xs: 55rem;
	--content-size--2xs: 30rem;
	--content-size--3xs: 20rem;

	--text-width: 60rem;

	--header-height--lg: 9rem;
	--header-height--xs: 7rem;
}
