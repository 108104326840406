@import 'styles/2-tools/tools.action.scss';
@import 'styles/50-themes/theme-codan/tools.action.scss';
@import 'styles/50-themes/theme-dark/2-tools/tools.action.scss';
@import 'styles/50-themes/theme-erhvervssikring/2-tools/tools.action.scss';
@import 'styles/50-themes/theme-sydbank/theme';

.Button {
	@include button;
}

:global(.Codan-theme) {
	.Button {
		@include buttonCodan;
	}
}

:global(.Dark-theme) {
	.Button {
		@include buttonDarkTheme;
	}
}

:global(.Erhvervssikring-theme) {
	.Button {
		@include buttonESK;
	}
}

:global(.Sydbank-theme) {
	.Button {
		@include buttonSydbankTheme;
	}
}
