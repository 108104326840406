@import 'styles/2-tools/tools.media-query';

.DescriptionList {
	hr {
		margin-top: var(--spacing--2xs);
		margin-bottom: var(--spacing--sm);
		border: 0;
		background-color: var(--color-sky-blue);
		height: 1px;
	}

	@include mq('md') {
		margin: var(--spacing--3xl) auto 0;
	}
	@include mq('md', max) {
		margin: var(--spacing--lg) auto 0;
	}
}
.DescriptionList_heading {
	font-weight: var(--font-weight--medium);
	font-size: var(--font-size--lg);
	@include mq('md') {
		margin-bottom: var(--spacing--base);
	}
	@include mq('md', max) {
		margin-bottom: var(--spacing--sm);
	}
}
.DescriptionList_item {
	@include mq('md') {
		margin-top: var(--spacing--xs);
	}
	@include mq('md', max) {
		margin-top: var(--spacing--2xs);
	}
	&:first-of-type {
		margin-top: 0;
	}
}

:global(.Dark-theme) {
	.DescriptionList_heading {
		color: var(--color-white);
	}
}

:global(.Sydbank-theme) {
	.DescriptionList_heading {
		@include mq('md', max) {
			font-size: var(--font-size--md);
		}
	}

	.DescriptionList_item {
		@include mq('md') {
			margin-top: 0;
		}
		@include mq('md', max) {
			overflow-wrap: anywhere;
			margin-top: var(--spacing--2xs);
		}
	}
}

:global(.Dark-theme.Sydbank-theme) {
	.DescriptionList_heading {
		@include mq('md', max) {
			font-size: var(--font-size--md);
		}
	}

	.DescriptionList_item {
		@include mq('md') {
			margin-top: 0;
		}
		@include mq('md', max) {
			margin-top: var(--spacing--2xs);
		}
	}
}
