@import 'src/styles/2-tools/tools.media-query';

@mixin sydbank-heading--sm {
	font-family: var(--font-secondary);
	font-weight: var(--font-weight--bold);
}

@mixin sydbank-heading--2xl {
	//styleName: Mob/H1; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
	font-family: var(--font-secondary);
	font-size: var(--font-size--5xl);
	font-weight: var(--font-weight--bold);
	line-height: var(--line-height--xs);
	letter-spacing: var(--letter-spacing--sm);

	// @include mq('lg') {
	// 	//styleName: H1; https://www.figma.com/file/yrNqovqY5WXL60eAO5Vkd6/ESK?node-id=135%3A3625
	// 	font-family: var(--font-primary);
	// 	font-size: var(--font-size--6xl);
	// 	font-weight: var(--font-weight--light);
	// 	line-height: var(--line-height--2xs);
	// 	letter-spacing: var(--letter-spacing--sm);
	// }
}

@mixin sydbank-paragraph {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--2xl);
}
