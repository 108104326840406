@import 'styles/2-tools/tools.media-query';
$bannerImageHeightLg: 50rem;
$bannerImageHeightSm: 30rem;
$stepIndicatorHeight: 30rem;

.MasterCardPage {
	display: flex;
	flex-wrap: wrap;
}

.MasterCardPage_bannerImage {
	position: relative;
	height: 100%;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	@include mq('lg') {
		max-height: $bannerImageHeightLg;
	}
	@include mq('lg', max) {
		max-height: $bannerImageHeightSm;
	}
}

.MasterCardPage_title {
	position: absolute;
	font-weight: var(--font-weight--normal);
	text-align: center;
	width: 100%;
	max-width: var(--content-size--default);
	top: 50%;
	left: 50%;
	@include mq('lg') {
		font-size: var(--font-size--8xl);
		transform: translate(-50%, -10rem);
	}
	@include mq('lg', max) {
		font-size: var(--font-size--xl);
		transform: translate(-50%);
	}
}

.MasterCardPage_content,
.MasterCardPage_attentionCards {
	width: 100%;
}

.MasterCardPage_confirmationCard {
	margin-top: var(--spacing--2xl);
	@include mq('md') {
		margin-bottom: calc(var(--spacing--lg) * -1);
	}
	@include mq('md', max) {
		margin-bottom: var(--spacing--xs);
	}
}
.MasterCardPage_background {
	display: none;
}

:global(.Dark-theme) {
	.MasterCardPage_stepindicator {
		order: 1;
	}
	.MasterCardPage_header {
		order: 2;
	}
	.MasterCardPage_attentionCards {
		order: 3;
	}
	.MasterCardPage_confirmationCard {
		margin: 0 auto;
	}
	.MasterCardPage_content {
		order: 4;
	}
	.MasterCardPage_title {
		color: var(--color-white);
	}
	.MasterCardPage___withBannerImage .MasterCardPage_stepindicator {
		@include mq('lg') {
			transform: translateY(calc($stepIndicatorHeight / -2));
			max-height: $stepIndicatorHeight;
		}
	}
	.MasterCardPage_header {
		@include mq('lg') {
			margin-top: 0;
		}
	}
	.MasterCardPage___withBannerImage .MasterCardPage_header {
		@include mq('lg') {
			margin-top: calc($stepIndicatorHeight / -2);
		}
	}
}
