@mixin buttonDarkTheme {
	&___secondary {
		background-color: transparent;
		border-width: var(--border--md);
		border-color: var(--color-white);
		color: var(--color-white);
		&:before {
			background-color: var(--color-secondary-light);
			border-color: var(--color-secondary-light);
		}
		&:hover,
		&:focus {
			&:before {
				background-color: var(--color-secondary);
			}
		}
	}
}
