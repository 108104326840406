@import 'styles/2-tools/_tools.typography-mixins';
$color-size: 1.1rem;

.Status {
	display: inline-block;
	padding: var(--spacing--2xs) var(--spacing--xs);
	margin-top: auto;
	border-radius: var(--corner-size--lg);
	&___default {
		background-color: var(--color-white);
	}
	&___contrast {
		background-color: var(--color-background-contrast);
	}
}

.Status_wrapper {
	display: flex;
	align-items: center;
}

.Status_color {
	display: block;
	width: $color-size;
	height: $color-size;
	border-radius: var(--corner-circle);
	margin-right: var(--spacing--2xs);
	&___active {
		background-color: var(--color-success);
	}
	&___pending {
		background-color: var(--color-pending);
	}
}

.Status_text {
	@include paragraph--sm;
	&:first-letter {
		text-transform: capitalize;
	}
}
