@import 'styles/2-tools/tools.media-query.scss';

.Factbox {
	@include mq('sm') {
		margin: var(--spacing--6xl) auto;
	}
	@include mq('sm', max) {
		margin: var(--spacing--5xl) auto;
	}
	&___vanilla {
		margin: 0;
	}
}

.Factbox_container {
	.Factbox___vanilla & {
		max-width: 100%;
		padding: 0;
	}
}

.Factbox_wrapper {
	text-align: center;
	background-color: var(--color-background-contrast-light);
	@include mq('sm') {
		padding: var(--spacing--3xl) var(--spacing--3xl) var(--spacing--4xl) var(--spacing--3xl);
	}
	@include mq('sm', max) {
		padding: var(--spacing--3xl) var(--spacing--lg) var(--spacing--4xl) var(--spacing--lg);
	}
	.Factbox___vanilla & {
		padding: 0;
		background: none;
	}
}

.Factbox_kicker {
	text-transform: uppercase;
	font-size: var(--font-size--sm);
	@include mq('sm') {
		margin-bottom: var(--spacing--lg);
	}
	@include mq('sm', max) {
		margin-bottom: var(--spacing--base);
	}
}
.Factbox_title {
	margin-bottom: var(--spacing--md);
}
.Factbox_text *:last-child {
	margin-bottom: 0;
}

.Factbox_item:last-child {
	margin-bottom: 0;
}
