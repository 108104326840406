@import 'styles/2-tools/tools.typography-mixins.scss';

.Accordion {
	margin-left: auto;
	margin-right: auto;

	@include mq('md', max) {
		margin-top: var(--spacing--5xl);
		margin-bottom: var(--spacing--6xl);
	}

	@include mq('md') {
		margin-top: var(--spacing--6xl);
		margin-bottom: var(--spacing--8xl);
	}
}

.Accordion_description {
	margin-top: var(--spacing--sm);
}

.Accordion_items {
	background-color: var(--color-athens-gray);
	color: var(--color-black);
	padding: 0;
	width: 100%;
	margin-top: var(--spacing--lg);

	:global(.Sydbank-theme) & {
		background-color: var(--color-tint-blue2);
	}
}

.Accordion_items > * {
	border-top: var(--border--md) solid var(--color-white);

	&:first-of-type {
		border-top: none;
	}

	:global(.Sydbank-theme) & {
		border-top: calc(var(--border--2xl) * 3) solid var(--color-white);
	}
}

:global(.Erhvervssikring-theme) {
	.Accordion_description {
		p {
			font-weight: var(--font-weight--light);
		}
	}
}

// :global(.Sydbank-theme) {

// }
