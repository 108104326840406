@import 'styles/2-tools/tools.behavior.scss';
@import 'styles/2-tools/tools.keyboard-focus.scss';
@import 'styles/2-tools/tools.media-query';
.Modal {
	z-index: var(--z-index--modal);
	position: fixed;
	width: 100vw;
	max-width: 100%;
	height: 100vh;
	align-items: center;
	justify-content: center;
	visibility: visible;
	display: flex;
	top: 0;
	left: 0;
	transition-delay: unset;
	padding: var(--spacing--md);
}

.Modal_overlay {
	background-color: var(--color-black);
	opacity: 0.6;

	@include transition {
		transition: var(--trans-time--standard) opacity ease;
	}
}

.Modal_dialog {
	width: 100%;
	max-height: 100%;
	max-width: 86rem;
	background-color: var(--color-white);
	z-index: 1;
	transform: scale(1);
	opacity: 1;
	box-shadow: 0.1em 0.1em 0.2em var(--shadow-color--mild);
	overflow-y: auto;
	text-align: left;

	&___small {
		min-height: 806px;
		width: 640px;

		background-color: var(--color-light);
	}

	@include mq('md', max) {
		padding: var(--spacing--4xl) var(--spacing--lg) var(--spacing--2xl);
	}
	@include mq('md') {
		padding: var(--spacing--5xl) var(--spacing--5xl) var(--spacing--3xl);
	}

	@include transition {
		transition: var(--trans-time--shorter) transform ease, var(--trans-time--shorter) opacity ease;
	}
}

.Modal_titleContainer {
	background-color: var(--color-primary-light);
	width: 100%;
	padding-top: var(--spacing--md);

	text-align: center;
	.Modal___confirmation & {
		background: none;
		@include mq('md', max) {
			padding-bottom: var(--spacing--xs);
		}
	}

	.Modal_titleContentContainer {
		padding-bottom: var(--spacing--base);

		.Modal_subTitle {
			font-weight: var(--font-weight--light);
		}
	}

	.Modal_titleImage img {
		max-height: 22rem;
	}

	.Modal___simple & {
		background: none;
		padding-top: var(--spacing--xs);
	}
}

.Modal_contentContainer {
	margin-top: var(--spacing--xl);
	.Modal___confirmation & {
		margin-top: var(--spacing--2xs);
		@include mq('md') {
			margin-bottom: var(--spacing--3xl);
		}
	}

	.Modal___simple & {
		margin-top: 0;
	}
}

.Modal_controls {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: var(--spacing--xl);
}

.Modal_controlsCancel {
	display: flex;
	justify-content: center;
	margin: var(--spacing--xs) auto 0;
	.Modal___withoutForm &,
	.Modal___simple &,
	.Modal___confirmation & {
		font-size: var(--font-size--sm);
		text-transform: uppercase;
	}
}
.Modal_close {
	position: absolute;
	background-color: transparent;
	right: var(--spacing--xs);
	top: var(--spacing--xs);
	cursor: pointer;
	height: 4rem;
	width: 4rem;
	padding: 0;
	border: none;
	border-radius: 50%;
	outline: none;
	&:focus {
		@include keyboard-focus {
			background-color: var(--color-primary);
		}
	}
}

.Modal_icon {
	max-width: 14rem;
	display: flex;
	justify-content: center;
	background-color: transparent;
	@include mq('md') {
		margin: var(--spacing--xs) auto var(--spacing--base);
	}
	@include mq('md', max) {
		margin: 0 auto var(--spacing--md);
	}
}

.Modal_IdleIcon {
	width: 14rem;
	height: 14rem;
	background-color: var(--color-primary-light);
	border-radius: var(--corner-circle);
	display: flex;
	justify-content: center;
	align-items: center;
}

.Modal_IdleSvg {
	max-width: 14rem;
	max-height: 7rem;
}

:global(.Codan-theme) {
	.Modal_close {
		&:focus {
			@include keyboard-focus {
				background-color: var(--color-tertiary);
			}
		}
	}
	.Modal_IdleSvg {
		max-width: 14rem;
		max-height: 10rem;
	}
}
