.u-content-section--background-light-gray {
	background-color: var(--color-background-contrast-light);
	@include mq('md') {
		margin-top: var(--spacing--8xl);
		margin-bottom: var(--spacing--8xl);
		padding: 0.01rem 0;
	}
	@include mq('md', max) {
		margin-top: var(--spacing--6xl);
		margin-bottom: var(--spacing--6xl);
		padding: 0.01rem 0;
	}
	.u-container--narrow div:first-child {
		@include mq('md') {
			margin-top: var(--spacing--6xl);
			margin-bottom: var(--spacing--6xl);
		}
		@include mq('md', max) {
			margin-top: var(--spacing--5xl);
			margin-bottom: var(--spacing--5xl);
		}
	}
}
