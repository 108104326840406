@mixin buttonESK {
	&___primary {
		&:hover,
		&:focus {
			background-color: var(--color-hover-primary);
		}
	}

	&__secondary {
		&:hover,
		&:focus {
			background-color: var(--color-functional-gray-blue-25);
		}
	}
}
