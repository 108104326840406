/* ===========================================
	utilities.container
	========================================= */
/**
	*  Utilities – utility- and helper classes
	*  with the ability to override anything
*/
@import 'src/styles/2-tools/tools.media-query.scss';

.Dark-theme {
	.u-container--narrow {
		max-width: var(--content-size--default);
		background-color: var(--color-background-lighter);
		@include mq('lg') {
			padding: 0 var(--spacing--4xl);
		}
	}
}
