@import 'styles/2-tools/tools.display-mixins.scss';

.Notification {
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: var(--color-primary);
	color: var(--color-light);

	&___isHidden {
		@include visually-hidden;
	}
}
