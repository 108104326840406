/* ------------------------------------ *\
		settings.typography
\* ------------------------------------ */

:root {
	// Font family
	--font-primary: 'Poppins', sans-serif;
	--font-secondary: 'Open Sans', sans-serif;

	// Font weights
	--font-weight--light: 300;
	--font-weight--normal: 400;
	--font-weight--medium: 500;
	--font-weight--semibold: 600;
	--font-weight--bold: 700;
	--font-weight--black: 800;
	--font-weight--extrablack: 900;

	// Font sizes
	--font-size--2xs: 1rem;
	--font-size--xs: 1.2rem;
	--font-size--sm: 1.4rem;
	--font-size--md: 1.6rem;
	--font-size--lg: 1.8rem;
	--font-size--xl: 2rem;
	--font-size--2xl: 2.2rem;
	--font-size--3xl: 2.4rem;
	--font-size--4xl: 2.6rem;
	--font-size--5xl: 3.4rem;
	--font-size--6xl: 4rem;
	--font-size--7xl: 4.6rem;
	--font-size--8xl: 6rem;

	// Line heights
	--line-height--2xs: 1;
	--line-height--xs: 1.06;
	--line-height--sm: 1.15;
	--line-height--md: 1.2;
	--line-height--lg: 1.25;
	--line-height--xl: 1.3;
	--line-height--2xl: 1.5;
	--line-height--3xl: 2;
	--line-height--4xl: 3;
	--line-height--5xl: 3.75;
}
