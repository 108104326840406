.u-header-section--background-white {
	background-color: var(--color-white);
}
.u-header-section--background-business-green {
	background-color: var(--color-business-green);
}
.u-header-section--background-business-light-green {
	background-color: var(--color-business-light-green);
}
.u-header-section--background-functional-dark-blue {
	background-color: var(--color-functional-dark-blue);
	color: var(--color-white);
}
.u-header-section--background-functional-gray-blue {
	background-color: var(--color-functional-gray-blue);
}
.u-header-section--background-functional-gray-blue50 {
	background-color: var(--color-functional-gray-blue-50);
}
.u-header-section--background-functional-gray-blue25 {
	background-color: var(--color-functional-gray-blue-25);
}
.u-header-section--background-functional-light-blue {
	background-color: var(--color-functional-light-blue);
}
.u-header-section--background-functional-black {
	background-color: var(--color-functional-black);
	color: var(--color-white);
}
.u-header-section--background-business-medium-gray {
	background-color: var(--color-business-medium-gray);
}
.u-header-section--background-business-medium-gray50 {
	background-color: var(--color-business-medium-gray-50);
}
.u-header-section--background-business-medium-gray25 {
	background-color: var(--color-business-medium-gray-25);
}
.u-header-section--background-business-light-gray {
	background-color: var(--color-business-light-gray);
}
