@import 'styles/2-tools/tools.media-query';

.ProductOverview {
	display: grid;
	grid-auto-rows: 1fr;
	grid-gap: var(--spacing--base);

	@include mq('lg', max) {
		grid-template-columns: repeat(3, 1fr);
	}

	@include mq('md', max) {
		grid-template-columns: repeat(2, 1fr);
	}

	@include mq('xs', max) {
		grid-template-columns: 1fr;
	}

	@include mq('lg') {
		grid-template-columns: repeat(4, 1fr);

		:global(.Erhvervssikring-theme) & {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

.ProductOverview_item {
	display: flex;
	flex-direction: column;
}

.ProductOverview_linkWrapper {
	text-decoration: none;

	:global(.Erhvervssikring-theme) & {
		height: 100%;
	}
}
