@import 'styles/2-tools/tools.media-query.scss';

.FactboxItem {
	text-align: left;
	@include mq('sm') {
		display: flex;
		margin: var(--spacing--base) auto var(--spacing--md) auto;
	}
	@include mq('sm', max) {
		display: block;
		margin: var(--spacing--md) auto;
	}
	&___vanilla {
		@include mq('sm', max) {
			display: flex;
		}
	}
}
$imageSize: 11rem;
.FactboxItem_imageContainer {
	width: 100%;
	max-width: $imageSize;
	height: $imageSize;
	min-width: $imageSize;
	@include mq('sm') {
		margin-right: var(--spacing--base);
	}
	@include mq('sm', max) {
		margin-left: auto;
		margin-right: auto;
	}
	.FactboxItem___vanilla & {
		height: auto;
		@include mq('sm', max) {
			margin-right: var(--spacing--base);
			margin-left: 0;
		}
	}
}

.FactboxItem_text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	*:last-child {
		margin-bottom: 0;
	}
	@include mq('sm') {
		margin-top: var(--spacing--2xs);
	}
	@include mq('sm', max) {
		margin-top: var(--spacing--base);
	}
	.FactboxItem___vanilla & {
		margin-top: 0;
	}
}
